/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import { FiBell } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';

interface ContainerProps {
  hasOpenNotification: boolean;
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  padding: 0;
  border: none;
  cursor: pointer;
  :hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  ${(props) => {
    if (props.hasOpenNotification) {
      return css`
        ::after {
          position: absolute;
          z-index: 10;
          content: '';
          border-radius: 50%;
          width: 10px;
          height: 10px;
          right: 10%;
          top: 0%;
          background-color: ${(props) => props.theme.colors.notification};
        }
      `;
    }
  }}
`;

export const NotificationIcon = styled(FiBell)<IconBaseProps>`
  color: ${(props) => props.theme.colors.darkText};
`;
