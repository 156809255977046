import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  list-style: none;
  overflow-y: auto;
`;

export const EmptyItem = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
`;

export const TagsList = styled.div`
  overflow-x: auto;
  min-height: 48px;
  padding: 4px 5px 12px;
`;
