/* eslint-disable @typescript-eslint/ban-types */
import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Sidebar } from './Sidebar';
import { FilterScreenProvider } from '@/components_v2/Filtersv3/filter-store';

export const TicketLayout: React.FC = ({ children }) => {
  return (
    <Flex flexDir="row" h="full" bg="#F7F8F9">
      <Sidebar />
      <Flex
        width="full"
        height="100vh"
        maxHeight="100vh"
        overflow="hidden"
        flex={1}
        flexDir="column"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export const withTicketLayout = <T extends object>(Component: React.FC<T>) => {
  return (props: T) => (
    <FilterScreenProvider screen="tickets">
      <TicketLayout>
        <Component {...props} />
      </TicketLayout>
    </FilterScreenProvider>
  );
};
