import Loading from '@/components/Loading';
import LoadingLayer from '@/screens/utils/LoadingLayer';
import { useAuth } from '@hooks/Authentication/auth';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AppRoutes from './app.routes';
import ExternalRoutes from './groups/external.routes';
import PublicRoutes from './public.routes';

const Routes: React.FC = () => {
  const { token, loading } = useAuth();

  if (loading) return <Loading />;

  return (
    <>
      <LoadingLayer />
      <Switch>
        <Route path="/app" component={AppRoutes} />
        <Route path="/auth" component={PublicRoutes} />
        <Route path="/external" component={ExternalRoutes} />
        <Route path="*">
          <Redirect to={token ? '/app' : `/auth`} />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
