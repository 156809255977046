/* eslint-disable no-case-declarations */
import config from '@/Constants';
import { useFilter } from '@/hooks/Filters/filter';
import { useModals } from '@/hooks/ModalManager';
import { useBatchPDFExporter } from '@/hooks/useBatchPDFExporter';
import { QueryResponse } from '@/modules/queryfilter/domain/interfaces';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import {
  Task,
  TaskResponsible,
} from '@/screens/registrations/Tasks/interfaces';
import debounce from '@/utils/debounce';
import { useToast } from '@chakra-ui/react';
import { RowSelectionState } from '@tanstack/react-table';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { EditTask } from '../components/EditTask';
import { TasksContextData } from './tasks.types';

const TasksContext = createContext<TasksContextData>({} as TasksContextData);

export const TasksProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [exportBatch, { loading: exportLoading }] = useBatchPDFExporter();
  const modals = useModals();
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>();

  const { getQueryString } = useFilter();
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [responsibleFilter, setResponsibleFilter] = useState<
    TaskResponsible | undefined
  >(undefined);

  const fetcher = async (url: string) => {
    const response = await AxiosHelper.get<QueryResponse<Task>>({ url });
    setHasNextPage(response.body.hasNextPage);
    setTotalItems(response.body.totalItems);
    return response.body.items;
  };

  const { data, mutate, isValidating } = useSWR(
    `${config.TASKS_URL}?page=${page}&size=${itemsPerPage}${queryString}${
      responsibleFilter === 'my_tasks' ? '&only_my_tasks=true' : ''
    }`,
    fetcher,
    { revalidateOnMount: true },
  );

  const taskViewRef = useRef<any>(null);
  const [debouncedQuery] = useState(() =>
    debounce(async (func: () => void) => {
      func();
    }, 250),
  );

  const openTaskView = useCallback((task: Task) => {
    taskViewRef.current?.open(task);
  }, []);

  const changeItemsPerPage = useCallback((items: number) => {
    setItemsPerPage(items);
    setPage(1);
    setRowSelection(undefined);
  }, []);

  useEffect(() => {
    debouncedQuery(() => {
      setPage(1);
      setQueryString(`${getQueryString()}${searchQuery || ''}`);
      setRowSelection(undefined);
    });
  }, [debouncedQuery, getQueryString, searchQuery]);

  useEffect(() => {
    mutate();
  }, [page, itemsPerPage, queryString, responsibleFilter, mutate]);

  const handleSearch = useCallback((searchStr: string) => {
    if (!searchStr || !searchStr.length) {
      setSearchQuery(undefined);
      return;
    }
    setSearchQuery(`&search=${searchStr}`);
  }, []);

  const changePage = useCallback((newPage: number) => {
    setPage(newPage);
    setRowSelection(undefined);
  }, []);

  const handleRefresh = useCallback(() => {
    setRowSelection(undefined);
    mutate();
  }, [mutate]);

  const openTaskForm = useCallback(
    (task?: Task) => {
      const id = 'edit-task';
      const onDismiss = () => {
        modals.close(id);
      };
      const onSuccess = (id?: string) => {
        onDismiss();
        handleRefresh();
        toast({
          title: id ? 'Tarefa atualizada' : 'Tarefa criada.',
          status: 'success',
        });
      };
      modals.drawer({
        id,
        size: 'lg',
        title: t('tasks.form.create_task'),
        body: (
          <EditTask task={task} onDismiss={onDismiss} onSuccess={onSuccess} />
        ),
      });
    },
    [handleRefresh, modals, toast],
  );

  const selectedIds = useMemo(() => {
    const rowIndexes = rowSelection ? Object.keys(rowSelection) : [];

    if (!rowIndexes.length) return [];

    const tasks = data || [];
    const ids = rowIndexes.reduce((prev, curr) => {
      const item = tasks[(curr as unknown) as number];
      if (item) {
        return [...prev, item.id];
      }
      return prev;
    }, [] as string[]);

    return ids;
  }, [data, rowSelection]);

  const exportBatchTasks = useCallback(async () => {
    if (!selectedIds.length) return;
    const rowIndexes = rowSelection ? Object.keys(rowSelection) : [];

    if (!rowIndexes) return;

    const tasks = data || [];

    const eventIds = rowIndexes.reduce((prev, curr) => {
      const item = tasks[(curr as unknown) as number];
      if (item.event_id) {
        return [...prev, item.event_id];
      }
      return prev;
    }, [] as string[]);

    if (!eventIds.length) return;

    await exportBatch(eventIds);
    setRowSelection({});
  }, [data, exportBatch, rowSelection, selectedIds]);

  const context = {
    openTaskView,
    openTaskForm,
    changePage,
    changeItemsPerPage,
    onSearch: handleSearch,
    responsibleFilter,
    itemsPerPage,
    page,
    hasNextPage,
    tasks: data || [],
    totalItems,
    refresh: handleRefresh,
    rowSelection,
    setRowSelection,
    selectedIds,
    isLoading: isValidating,
    exportBatchTasks,
    onChangeResponsible: setResponsibleFilter,
  };

  return (
    <TasksContext.Provider value={context}>{children}</TasksContext.Provider>
  );
};

export function useTasks(
  selector?: (params: TasksContextData) => Partial<TasksContextData>,
): TasksContextData {
  const context: TasksContextData = useContext(TasksContext);

  if (!context) {
    throw new Error('useTasks must be used within an TasksProvider');
  }
  const memoized = useMemo(() => {
    if (selector) return selector(context);
    return context;
  }, [context, selector]);

  return memoized as TasksContextData;
}
