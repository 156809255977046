export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
};

export type AccessSettings = {
  __typename?: 'AccessSettings';
  teams: Maybe<Array<IdName>>;
  users: Maybe<Array<IdName>>;
};

export type AccessSettingsInput = {
  teamIds?: InputMaybe<Array<Scalars['ID']>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ActionEmailChecklist = {
  __typename?: 'ActionEmailChecklist';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  question: Maybe<Question>;
  questionId: Maybe<Scalars['ID']>;
  sendChecklistOpenFlow: Maybe<Scalars['Boolean']>;
};

export type ActionEmailChecklistInput = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  questionId: Scalars['ID'];
  sendChecklistOpenFlow?: InputMaybe<Scalars['Boolean']>;
};

export type ActionEmailUser = {
  __typename?: 'ActionEmailUser';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  sendChecklistOpenFlow: Maybe<Scalars['Boolean']>;
  users: Maybe<Array<User>>;
  value: Maybe<Array<Scalars['ID']>>;
};

export type ActionEmailUserInput = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sendChecklistOpenFlow?: InputMaybe<Scalars['Boolean']>;
  value: Array<Scalars['ID']>;
};

export type ActionPropertyUpdate = {
  __typename?: 'ActionPropertyUpdate';
  automationProperty: Maybe<Property>;
  automationPropertyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  question: Maybe<Question>;
  questionId: Maybe<Scalars['ID']>;
};

export type ActionPropertyUpdateInput = {
  automationPropertyId: Scalars['ID'];
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  questionId: Scalars['ID'];
};

export type ActionTask = {
  __typename?: 'ActionTask';
  formulary: Maybe<Formulary>;
  formularyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lastMemberTask: Maybe<Scalars['Boolean']>;
  localSet: Maybe<Local>;
  localSetId: Maybe<Scalars['ID']>;
  localTask: Maybe<Scalars['Boolean']>;
  localTicketProperty: Maybe<Property>;
  localTicketPropertyId: Maybe<Scalars['ID']>;
  memberQuestion: Maybe<Question>;
  memberQuestionId: Maybe<Scalars['ID']>;
  memberSet: Maybe<User>;
  memberSetId: Maybe<Scalars['ID']>;
  memberTask: Maybe<Scalars['Boolean']>;
  taskAttributes: Maybe<Array<ActionTaskAttributes>>;
};

export type ActionTaskAttributes = {
  __typename?: 'ActionTaskAttributes';
  attributeName: Maybe<Scalars['String']>;
  question: Maybe<Question>;
  questionId: Maybe<Scalars['ID']>;
};

export type ActionTaskAttributesInput = {
  attributeName: Scalars['String'];
  questionId: Scalars['ID'];
};

export type ActionTaskInput = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  formularyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  lastMemberTask?: InputMaybe<Scalars['Boolean']>;
  localSetId?: InputMaybe<Scalars['ID']>;
  localTask?: InputMaybe<Scalars['Boolean']>;
  localTicketPropertyId?: InputMaybe<Scalars['ID']>;
  memberQuestionId?: InputMaybe<Scalars['ID']>;
  memberSetId?: InputMaybe<Scalars['ID']>;
  memberTask?: InputMaybe<Scalars['Boolean']>;
  taskAttributes?: InputMaybe<Array<ActionTaskAttributesInput>>;
};

export type ActionWebhook = {
  __typename?: 'ActionWebhook';
  endpoint: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type ActionWebhookInput = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  endpoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Answer = {
  __typename?: 'Answer';
  answerOptions: Maybe<Array<Scalars['String']>>;
  answeredAt: Maybe<Scalars['BigInt']>;
  authorId: Maybe<Scalars['ID']>;
  clientVersion: Maybe<Scalars['String']>;
  collectionItemId: Maybe<Scalars['ID']>;
  comment: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  deviceUuid: Maybe<Scalars['String']>;
  event: Event;
  eventId: Maybe<Scalars['ID']>;
  externalCreatedAt: Maybe<Scalars['BigInt']>;
  externalUpdatedAt: Maybe<Scalars['BigInt']>;
  facility: Maybe<Scalars['String']>;
  failUrlValidation: Maybe<Scalars['Boolean']>;
  flaggedAnswers: Maybe<Array<Answer>>;
  flaggedOptions: Maybe<Array<Option>>;
  formatContent: Maybe<Scalars['String']>;
  hasErrorInMedia: Maybe<Scalars['Boolean']>;
  hasMedia: Maybe<Scalars['Boolean']>;
  hasRemoteValidation: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  item: Item;
  itemId: Maybe<Scalars['ID']>;
  jobMediaCalled: Maybe<Scalars['Boolean']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  mediaExternalProcessed: Maybe<Scalars['Boolean']>;
  mediaProcessed: Maybe<Scalars['Boolean']>;
  numeric: Maybe<Scalars['Float']>;
  option: Maybe<Option>;
  optionId: Maybe<Scalars['ID']>;
  question: Question;
  questionId: Maybe<Scalars['ID']>;
  questionType: Maybe<Scalars['String']>;
  topics: Maybe<Array<Topic>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Answer. */
export type AnswerConnection = {
  __typename?: 'AnswerConnection';
  /** A list of edges. */
  edges: Array<AnswerEdge>;
  /** A list of nodes. */
  nodes: Array<Answer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AnswerEdge = {
  __typename?: 'AnswerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Answer>;
};

export type AnswerUpdateAttributes = {
  collectionItemId?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  itemId?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  questionType?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of AnswerUpdate */
export type AnswerUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectionItemId?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  optionId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AnswerUpdate. */
export type AnswerUpdatePayload = {
  __typename?: 'AnswerUpdatePayload';
  answer: Maybe<Answer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

export type AnswersInput = {
  answeredAt: Scalars['BigInt'];
  collectionItemId?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  id: Scalars['ID'];
  questionId: Scalars['ID'];
};

/** Autogenerated input type of AnswersUpdateInBatch */
export type AnswersUpdateInBatchInput = {
  answers: Array<AnswerUpdateAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AnswersUpdateInBatch. */
export type AnswersUpdateInBatchPayload = {
  __typename?: 'AnswersUpdateInBatchPayload';
  answers: Maybe<Array<Answer>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

export type AutomationFlowFilter = {
  AND?: InputMaybe<Array<AutomationFlowFilter>>;
  automationGroupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum AutomationFlowOrderBy {
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

export type AutomationGroupFilter = {
  AND?: InputMaybe<Array<AutomationGroupFilter>>;
  id?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum AutomationGroupOrderBy {
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

export type AutomationPropertyFilter = {
  AND?: InputMaybe<Array<AutomationPropertyFilter>>;
  automationFlowId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum AutomationPropertyOrderBy {
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

/** property types enum */
export enum AutomationPropertyTypes {
  /** Numérico */
  Number = 'number',
  /** Texto */
  Text = 'text'
}

export type AutomationStepActionFilter = {
  AND?: InputMaybe<Array<AutomationStepActionFilter>>;
  automationStepId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export enum AutomationStepActionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC'
}

export type AutomationStepFilter = {
  AND?: InputMaybe<Array<AutomationStepFilter>>;
  automationFlowId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  onlyProgressing?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum AutomationStepOrderBy {
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

export type AutomationTicketFilter = {
  AND?: InputMaybe<Array<AutomationTicketFilter>>;
  automationFlowId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  macroFilters?: InputMaybe<Scalars['JSON']>;
  search?: InputMaybe<Scalars['String']>;
  ticketPropertyAnswers?: InputMaybe<Array<Scalars['JSON']>>;
};

export enum AutomationTicketOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC'
}

export type Collection = {
  __typename?: 'Collection';
  active: Maybe<Scalars['Boolean']>;
  addressActive: Maybe<Scalars['Boolean']>;
  authorId: Maybe<Scalars['ID']>;
  collectionGroupId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importId: Maybe<Scalars['ID']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  referenceUniq: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CollectionFilter = {
  AND?: InputMaybe<Array<CollectionFilter>>;
  id?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum CollectionOrderBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Autogenerated input type of CreateEmail */
export type CreateEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  templateTaskUrlId: Scalars['ID'];
};

/** Autogenerated return type of CreateEmail. */
export type CreateEmailPayload = {
  __typename?: 'CreateEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  templateTaskUrlEmail: Maybe<TemplateTaskUrlEmail>;
};

/** Autogenerated input type of Create */
export type CreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailsDirect?: InputMaybe<Array<Scalars['String']>>;
  templateTaskId: Scalars['ID'];
  urlType?: InputMaybe<TemplateUrlTypes>;
};

/** Autogenerated return type of Create. */
export type CreatePayload = {
  __typename?: 'CreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  templateTaskUrl: Maybe<TemplateTaskUrl>;
};

export enum DelayTypeTriggers {
  /** Task schedule execution */
  Delay = 'delay',
  /** Task is immediate execution */
  Immediate = 'immediate'
}

/** Autogenerated input type of DeleteEmail */
export type DeleteEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteEmail. */
export type DeleteEmailPayload = {
  __typename?: 'DeleteEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  templateTaskUrlEmail: Maybe<TemplateTaskUrlEmail>;
};

/** Autogenerated input type of DeleteInBatchPublicTasks */
export type DeleteInBatchPublicTasksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteInBatchPublicTasks. */
export type DeleteInBatchPublicTasksPayload = {
  __typename?: 'DeleteInBatchPublicTasksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  ids: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated input type of Delete */
export type DeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of Delete. */
export type DeletePayload = {
  __typename?: 'DeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  templateTaskUrl: Maybe<TemplateTaskUrl>;
};

export type Event = {
  __typename?: 'Event';
  author: Maybe<User>;
  authorId: Maybe<Scalars['ID']>;
  createOrigin: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  executionDate: Maybe<Scalars['ISO8601Date']>;
  executionTime: Maybe<Scalars['String']>;
  executionTimeZone: Maybe<Scalars['String']>;
  externalCreatedAt: Maybe<Scalars['BigInt']>;
  externalUpdatedAt: Maybe<Scalars['BigInt']>;
  finishedAt: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  items: Maybe<Array<Item>>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  parentableId: Maybe<Scalars['ID']>;
  parentableType: Maybe<Scalars['String']>;
  rid: Maybe<Scalars['String']>;
  status: Maybe<EventStatus>;
  task: Maybe<Task>;
  updatedAt: Scalars['ISO8601DateTime'];
  userProfileId: Maybe<Scalars['ID']>;
  validated: Maybe<Scalars['Boolean']>;
};

/** Event status enum */
export enum EventStatus {
  /** Atrasada */
  Delayed = 'delayed',
  /** Concluída */
  Done = 'done',
  /** Em aberto */
  Draft = 'draft',
  /** Reagendada */
  Rescheduled = 'rescheduled',
  /** Iniciada */
  Started = 'started'
}

export type Export = {
  __typename?: 'Export';
  author: Maybe<User>;
  authorId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  dateEnd: Maybe<Scalars['ISO8601Date']>;
  dateStart: Maybe<Scalars['ISO8601Date']>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  emailsForSend: Maybe<Array<Scalars['String']>>;
  exportColumns: Maybe<Array<Scalars['String']>>;
  exportErrors: Maybe<Array<Scalars['String']>>;
  exportOptions: Maybe<Scalars['JSON']>;
  exportType: Maybe<ExportTypes>;
  exportWithErrors: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['Int']>;
  parentableType: Maybe<ExportParentableTypes>;
  pivoted: Maybe<Scalars['Boolean']>;
  progress: Maybe<Scalars['Int']>;
  sqlQuery: Maybe<Scalars['String']>;
  status: Maybe<ExportStatus>;
  titleEvent: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  url: Maybe<Scalars['String']>;
};

/** The connection type for Export. */
export type ExportConnection = {
  __typename?: 'ExportConnection';
  /** A list of edges. */
  edges: Array<ExportEdge>;
  /** A list of nodes. */
  nodes: Array<Export>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExportEdge = {
  __typename?: 'ExportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Export>;
};

/** Export parentable types enum */
export enum ExportParentableTypes {
  /** Planos de Ação */
  ActionPlans = 'action_plans',
  /** Automação Webhook */
  AutomationWebhook = 'automation_webhook',
  /** Checklist Público */
  ChecklistPublic = 'checklist_public',
  /** Coleções */
  Collections = 'collections',
  /** Dashboard */
  Dashboard = 'dashboard',
  /** Evento */
  Events = 'events',
  /** Formulário */
  Formularies = 'formularies',
  /** Locais */
  Locals = 'locals',
  /** Inspeções */
  Schedules = 'schedules',
  /** Respostas de tarefas */
  TaskAnswers = 'task_answers',
  /** Tarefa */
  Tasks = 'tasks',
  /** Tickets */
  Tickets = 'tickets',
  /** Usuários */
  Users = 'users'
}

/** Export status enum */
export enum ExportStatus {
  /** Finalizado */
  Done = 'done',
  /** Fracassado */
  Failure = 'failure',
  /** Pendente */
  Pending = 'pending',
  /** Processando */
  Processing = 'processing'
}

/** Export types enum */
export enum ExportTypes {
  /** Valores separados por vírgula (.csv) */
  Csv = 'csv',
  /** PDF */
  Pdf = 'pdf',
  /** Power Point (.pptx) */
  Pptx = 'pptx',
  /** Excel (xlsx) */
  Xlsx = 'xlsx',
  /** Arquivos zipados (.zip) */
  Zip = 'zip'
}

/** Autogenerated input type of FinishTask */
export type FinishTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

/** Autogenerated return type of FinishTask. */
export type FinishTaskPayload = {
  __typename?: 'FinishTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  task: Maybe<Task>;
};

export type Flow = {
  __typename?: 'Flow';
  accessSettings: Maybe<AccessSettings>;
  authorId: Maybe<Scalars['ID']>;
  automationGroup: Maybe<Group>;
  automationGroupId: Maybe<Scalars['ID']>;
  automationProperties: Maybe<Array<Property>>;
  automationSteps: Maybe<Array<Step>>;
  automationTickets: Maybe<Array<Ticket>>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  showCustomCode: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of FlowsCreate */
export type FlowsCreateInput = {
  accessSettings?: InputMaybe<AccessSettingsInput>;
  automationGroupId?: InputMaybe<Scalars['ID']>;
  automationPropertiesAttributes?: InputMaybe<Array<PropertiesInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of FlowsCreate. */
export type FlowsCreatePayload = {
  __typename?: 'FlowsCreatePayload';
  automationFlow: Maybe<Flow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of FlowsDelete */
export type FlowsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of FlowsDelete. */
export type FlowsDeletePayload = {
  __typename?: 'FlowsDeletePayload';
  automationFlow: Maybe<Flow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of FlowsUpdate */
export type FlowsUpdateInput = {
  accessSettings?: InputMaybe<AccessSettingsInput>;
  automationGroupId?: InputMaybe<Scalars['ID']>;
  automationPropertiesAttributes?: InputMaybe<Array<PropertiesInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of FlowsUpdate. */
export type FlowsUpdatePayload = {
  __typename?: 'FlowsUpdatePayload';
  automationFlow: Maybe<Flow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

export type Formulary = {
  __typename?: 'Formulary';
  authorId: Maybe<Scalars['ID']>;
  clonedFromId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  disableAnswerHistory: Maybe<Scalars['Boolean']>;
  disableAttachments: Maybe<Scalars['Boolean']>;
  disableComments: Maybe<Scalars['Boolean']>;
  disableSubtasks: Maybe<Scalars['Boolean']>;
  enabled: Maybe<Scalars['Boolean']>;
  externalCreatedAt: Maybe<Scalars['Int']>;
  externalUpdatedAt: Maybe<Scalars['Int']>;
  formularyGroupId: Maybe<Scalars['ID']>;
  formularyType: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importId: Maybe<Scalars['ID']>;
  lastAuthorId: Maybe<Scalars['ID']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  parentOptionId: Maybe<Scalars['ID']>;
  parentQuestionId: Maybe<Scalars['ID']>;
  position: Maybe<Scalars['Int']>;
  recurrent: Maybe<Scalars['Boolean']>;
  required: Maybe<Scalars['Boolean']>;
  sections: Maybe<Array<Section>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Formulary. */
export type FormularyConnection = {
  __typename?: 'FormularyConnection';
  /** A list of edges. */
  edges: Array<FormularyEdge>;
  /** A list of nodes. */
  nodes: Array<Formulary>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FormularyEdge = {
  __typename?: 'FormularyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Formulary>;
};

export type FormularyGroup = {
  __typename?: 'FormularyGroup';
  authorId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  formularies: Maybe<Array<Formulary>>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of GetTask */
export type GetTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

/** Autogenerated return type of GetTask. */
export type GetTaskPayload = {
  __typename?: 'GetTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  task: Maybe<Task>;
};

export type Group = {
  __typename?: 'Group';
  authorId: Maybe<Scalars['ID']>;
  automationFlows: Maybe<Array<Flow>>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of GroupsCreate */
export type GroupsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of GroupsCreate. */
export type GroupsCreatePayload = {
  __typename?: 'GroupsCreatePayload';
  automationGroup: Maybe<Group>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of GroupsDelete */
export type GroupsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of GroupsDelete. */
export type GroupsDeletePayload = {
  __typename?: 'GroupsDeletePayload';
  automationGroup: Maybe<Group>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of GroupsUpdate */
export type GroupsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of GroupsUpdate. */
export type GroupsUpdatePayload = {
  __typename?: 'GroupsUpdatePayload';
  automationGroup: Maybe<Group>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of HideTask */
export type HideTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  hideTask: Scalars['Boolean'];
  taskId: Scalars['ID'];
};

/** Autogenerated return type of HideTask. */
export type HideTaskPayload = {
  __typename?: 'HideTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  task: Maybe<Task>;
};

export type IdName = {
  __typename?: 'IdName';
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
};

export type Import = {
  __typename?: 'Import';
  arrayErrors: Maybe<Scalars['JSON']>;
  author: Maybe<User>;
  authorId: Maybe<Scalars['ID']>;
  createOrigin: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  dataContent: Maybe<Scalars['JSON']>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  exceededMaxRows: Maybe<Scalars['Boolean']>;
  extraParams: Maybe<Scalars['JSON']>;
  fileTemporaryUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importErrors: Maybe<Array<Scalars['String']>>;
  importType: Maybe<ImportTypes>;
  importWithErrors: Maybe<Scalars['Boolean']>;
  lastModifiedAt: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  progress: Maybe<Scalars['Int']>;
  status: Maybe<ImportStatus>;
  token: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  wrongCountColumns: Maybe<Scalars['Boolean']>;
};

/** The connection type for Import. */
export type ImportConnection = {
  __typename?: 'ImportConnection';
  /** A list of edges. */
  edges: Array<ImportEdge>;
  /** A list of nodes. */
  nodes: Array<Import>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ImportEdge = {
  __typename?: 'ImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Import>;
};

/** Import status enum */
export enum ImportStatus {
  /** Finalizado */
  Done = 'done',
  /** Fracassado */
  Failure = 'failure',
  /** Pendente */
  Pending = 'pending',
  /** Processando */
  Processing = 'processing',
  /** Validando */
  Validating = 'validating'
}

/** Import Types enum */
export enum ImportTypes {
  /** Coleções */
  Collection = 'collection',
  /** Item de Coleção */
  CollectionItem = 'collection_item',
  /** Formulários */
  Formulary = 'formulary',
  /** Locais */
  Local = 'local',
  /** Agendamentos */
  Schedule = 'schedule',
  /** Tarefas */
  Task = 'task',
  /** Tickets de Automação */
  Ticket = 'ticket',
  /** Usuários */
  User = 'user'
}

export type Item = {
  __typename?: 'Item';
  answers: Maybe<Array<Answer>>;
  authorId: Maybe<Scalars['ID']>;
  collectibleId: Maybe<Scalars['ID']>;
  collectibleName: Maybe<Scalars['String']>;
  collectibleType: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  deviceUuid: Maybe<Scalars['String']>;
  editFromId: Maybe<Scalars['ID']>;
  event: Maybe<Event>;
  eventId: Maybe<Scalars['ID']>;
  externalCreatedAt: Maybe<Scalars['BigInt']>;
  externalUpdatedAt: Maybe<Scalars['BigInt']>;
  finishedAt: Maybe<Scalars['BigInt']>;
  formulary: Maybe<Formulary>;
  formularyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  invalidAnswers: Maybe<Scalars['Boolean']>;
  itemIsCollectible: Maybe<Scalars['Boolean']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  status: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of LeaveTask */
export type LeaveTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

/** Autogenerated return type of LeaveTask. */
export type LeaveTaskPayload = {
  __typename?: 'LeaveTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  task: Maybe<Task>;
};

export type Local = {
  __typename?: 'Local';
  active: Maybe<Scalars['Boolean']>;
  addressName: Maybe<Scalars['String']>;
  authorId: Maybe<Scalars['ID']>;
  city: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  externalCreatedAt: Maybe<Scalars['BigInt']>;
  externalUpdatedAt: Maybe<Scalars['BigInt']>;
  fantasyName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importId: Maybe<Scalars['ID']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Enumare possibly filtered columns */
export enum MacroColumnFilter {
  /** Column action_plan_id */
  ActionPlanId = 'action_plan_id',
  /** Column active */
  Active = 'active',
  /** Column address_name */
  AddressName = 'address_name',
  /** Column answered_at */
  AnsweredAt = 'answered_at',
  /** Column author_id */
  AuthorId = 'author_id',
  /** Column author_name */
  AuthorName = 'author_name',
  /** Column auto_finish */
  AutoFinish = 'auto_finish',
  /** Column automation_flow_id */
  AutomationFlowId = 'automation_flow_id',
  /** Column automation_step_id */
  AutomationStepId = 'automation_step_id',
  /** Column automation_ticket_id */
  AutomationTicketId = 'automation_ticket_id',
  /** Column automation_ticket_step_id */
  AutomationTicketStepId = 'automation_ticket_step_id',
  /** Column avatar_url */
  AvatarUrl = 'avatar_url',
  /** Column charge */
  Charge = 'charge',
  /** Column children_questions_count */
  ChildrenQuestionsCount = 'children_questions_count',
  /** Column city */
  City = 'city',
  /** Column client_version */
  ClientVersion = 'client_version',
  /** Column cloned_from_id */
  ClonedFromId = 'cloned_from_id',
  /** Column code */
  Code = 'code',
  /** Column code_max_char */
  CodeMaxChar = 'code_max_char',
  /** Column code_seq */
  CodeSeq = 'code_seq',
  /** Column collection_item_id */
  CollectionItemId = 'collection_item_id',
  /** Column color */
  Color = 'color',
  /** Column comment */
  Comment = 'comment',
  /** Column conditional */
  Conditional = 'conditional',
  /** Column confirmed_at */
  ConfirmedAt = 'confirmed_at',
  /** Column content */
  Content = 'content',
  /** Column count_answers */
  CountAnswers = 'count_answers',
  /** Column country */
  Country = 'country',
  /** Column create_origin */
  CreateOrigin = 'create_origin',
  /** Column created_at */
  CreatedAt = 'created_at',
  /** Column custom_code */
  CustomCode = 'custom_code',
  /** Column custom_question_id */
  CustomQuestionId = 'custom_question_id',
  /** Column dashboard_only */
  DashboardOnly = 'dashboard_only',
  /** Column date_end */
  DateEnd = 'date_end',
  /** Column date_start */
  DateStart = 'date_start',
  /** Column decimal_precision */
  DecimalPrecision = 'decimal_precision',
  /** Column deleted_at */
  DeletedAt = 'deleted_at',
  /** Column description */
  Description = 'description',
  /** Column device_info */
  DeviceInfo = 'device_info',
  /** Column device_uuid */
  DeviceUuid = 'device_uuid',
  /** Column discussion_status_type_id */
  DiscussionStatusTypeId = 'discussion_status_type_id',
  /** Column discussion_topic_id */
  DiscussionTopicId = 'discussion_topic_id',
  /** Column document */
  Document = 'document',
  /** Column done_with_force_automation_trigger_id */
  DoneWithForceAutomationTriggerId = 'done_with_force_automation_trigger_id',
  /** Column email */
  Email = 'email',
  /** Column enabled */
  Enabled = 'enabled',
  /** Column event_author_id */
  EventAuthorId = 'event_author_id',
  /** Column event_id */
  EventId = 'event_id',
  /** Column event_parentable_id */
  EventParentableId = 'event_parentable_id',
  /** Column event_parentable_type */
  EventParentableType = 'event_parentable_type',
  /** Column execution_date */
  ExecutionDate = 'execution_date',
  /** Column execution_time */
  ExecutionTime = 'execution_time',
  /** Column execution_time_zone */
  ExecutionTimeZone = 'execution_time_zone',
  /** Column external_created_at */
  ExternalCreatedAt = 'external_created_at',
  /** Column external_updated_at */
  ExternalUpdatedAt = 'external_updated_at',
  /** Column facility */
  Facility = 'facility',
  /** Column fail_url_validation */
  FailUrlValidation = 'fail_url_validation',
  /** Column fantasy_name */
  FantasyName = 'fantasy_name',
  /** Column finished_at */
  FinishedAt = 'finished_at',
  /** Column first_login */
  FirstLogin = 'first_login',
  /** Column flagged_event */
  FlaggedEvent = 'flagged_event',
  /** Column formulary_id */
  FormularyId = 'formulary_id',
  /** Column full_name */
  FullName = 'full_name',
  /** Column has_comments */
  HasComments = 'has_comments',
  /** Column has_error_in_media */
  HasErrorInMedia = 'has_error_in_media',
  /** Column has_media */
  HasMedia = 'has_media',
  /** Column has_remote_validation */
  HasRemoteValidation = 'has_remote_validation',
  /** Column hide_task */
  HideTask = 'hide_task',
  /** Column id */
  Id = 'id',
  /** Column image_url */
  ImageUrl = 'image_url',
  /** Column import_id */
  ImportId = 'import_id',
  /** Column input_mask */
  InputMask = 'input_mask',
  /** Column is_default */
  IsDefault = 'is_default',
  /** Column is_dev */
  IsDev = 'is_dev',
  /** Column is_owner */
  IsOwner = 'is_owner',
  /** Column item_id */
  ItemId = 'item_id',
  /** Column job_media_called */
  JobMediaCalled = 'job_media_called',
  /** Column label */
  Label = 'label',
  /** Column last_author_id */
  LastAuthorId = 'last_author_id',
  /** Column last_modified_at */
  LastModifiedAt = 'last_modified_at',
  /** Column last_reset_password_at */
  LastResetPasswordAt = 'last_reset_password_at',
  /** Column latitude */
  Latitude = 'latitude',
  /** Column limit_connections */
  LimitConnections = 'limit_connections',
  /** Column local_group_id */
  LocalGroupId = 'local_group_id',
  /** Column local_id */
  LocalId = 'local_id',
  /** Column locked_at */
  LockedAt = 'locked_at',
  /** Column longitude */
  Longitude = 'longitude',
  /** Column max_children */
  MaxChildren = 'max_children',
  /** Column max_pictures */
  MaxPictures = 'max_pictures',
  /** Column media_external_processed */
  MediaExternalProcessed = 'media_external_processed',
  /** Column media_processed */
  MediaProcessed = 'media_processed',
  /** Column member_id */
  MemberId = 'member_id',
  /** Column meta */
  Meta = 'meta',
  /** Column name */
  Name = 'name',
  /** Column number_seq_v2 */
  NumberSeqV2 = 'number_seq_v2',
  /** Column numeric */
  Numeric = 'numeric',
  /** Column option_id */
  OptionId = 'option_id',
  /** Column origin_id */
  OriginId = 'origin_id',
  /** Column origin_property */
  OriginProperty = 'origin_property',
  /** Column origin_type */
  OriginType = 'origin_type',
  /** Column parent_event_id */
  ParentEventId = 'parent_event_id',
  /** Column parent_item_id */
  ParentItemId = 'parent_item_id',
  /** Column parent_option_id */
  ParentOptionId = 'parent_option_id',
  /** Column parent_question_id */
  ParentQuestionId = 'parent_question_id',
  /** Column parent_task_id */
  ParentTaskId = 'parent_task_id',
  /** Column parentable_id */
  ParentableId = 'parentable_id',
  /** Column parentable_type */
  ParentableType = 'parentable_type',
  /** Column password_digest */
  PasswordDigest = 'password_digest',
  /** Column permissions */
  Permissions = 'permissions',
  /** Column permit_create_task */
  PermitCreateTask = 'permit_create_task',
  /** Column phone */
  Phone = 'phone',
  /** Column populate_from_attributes */
  PopulateFromAttributes = 'populate_from_attributes',
  /** Column position */
  Position = 'position',
  /** Column public_event */
  PublicEvent = 'public_event',
  /** Column public_task */
  PublicTask = 'public_task',
  /** Column qr_code_only */
  QrCodeOnly = 'qr_code_only',
  /** Column question_id */
  QuestionId = 'question_id',
  /** Column question_type */
  QuestionType = 'question_type',
  /** Column read_all */
  ReadAll = 'read_all',
  /** Column read_other_profiles */
  ReadOtherProfiles = 'read_other_profiles',
  /** Column required */
  Required = 'required',
  /** Column reset_password_sent_at */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** Column reset_password_time */
  ResetPasswordTime = 'reset_password_time',
  /** Column reset_password_token */
  ResetPasswordToken = 'reset_password_token',
  /** Column review_on */
  ReviewOn = 'review_on',
  /** Column rid */
  Rid = 'rid',
  /** Column score_weight */
  ScoreWeight = 'score_weight',
  /** Column search_terms */
  SearchTerms = 'search_terms',
  /** Column section_id */
  SectionId = 'section_id',
  /** Column state */
  State = 'state',
  /** Column status */
  Status = 'status',
  /** Column subtitle */
  Subtitle = 'subtitle',
  /** Column task_button_label */
  TaskButtonLabel = 'task_button_label',
  /** Column task_create */
  TaskCreate = 'task_create',
  /** Column task_formulary_id */
  TaskFormularyId = 'task_formulary_id',
  /** Column template_task_id */
  TemplateTaskId = 'template_task_id',
  /** Column template_task_url_id */
  TemplateTaskUrlId = 'template_task_url_id',
  /** Column template_url_email_id */
  TemplateUrlEmailId = 'template_url_email_id',
  /** Column ticket_code */
  TicketCode = 'ticket_code',
  /** Column tip_text */
  TipText = 'tip_text',
  /** Column title */
  Title = 'title',
  /** Column topic_activity_type */
  TopicActivityType = 'topic_activity_type',
  /** Column updated_at */
  UpdatedAt = 'updated_at',
  /** Column use_native_camera */
  UseNativeCamera = 'use_native_camera',
  /** Column user_origin */
  UserOrigin = 'user_origin',
  /** Column user_profile_id */
  UserProfileId = 'user_profile_id',
  /** Column user_type */
  UserType = 'user_type',
  /** Column username */
  Username = 'username',
  /** Column uuid_devices */
  UuidDevices = 'uuid_devices',
  /** Column validated */
  Validated = 'validated',
  /** Column validation_min_char */
  ValidationMinChar = 'validation_min_char',
  /** Column validation_url */
  ValidationUrl = 'validation_url',
  /** Column workspace_name */
  WorkspaceName = 'workspace_name'
}

export type MacroFilter = {
  __typename?: 'MacroFilter';
  key: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

/** The connection type for MacroFilter. */
export type MacroFilterConnection = {
  __typename?: 'MacroFilterConnection';
  /** A list of edges. */
  edges: Array<MacroFilterEdge>;
  /** A list of nodes. */
  nodes: Array<MacroFilter>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MacroFilterEdge = {
  __typename?: 'MacroFilterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<MacroFilter>;
};

/** Enumare possibly filtered table */
export enum MacroTableFilter {
  /** Order by createdAt desc */
  Answers = 'answers',
  /** Order by updatedAt desc */
  AutomationTickets = 'automation_tickets',
  /** Order by createdAt asc */
  DiscussionComments = 'discussion_comments',
  /** Order by createdAt asc */
  DiscussionStatusTypes = 'discussion_status_types',
  /** Order by createdAt asc */
  DiscussionTopics = 'discussion_topics',
  /** Order by updatedAt desc */
  Events = 'events',
  /** Order by updatedAt desc */
  Locals = 'locals',
  /** Order by updatedAt asc */
  Questions = 'questions',
  /** Order by updatedAt desc */
  Tasks = 'tasks',
  /** Order by createdAt asc */
  Users = 'users'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Updates multiple answers by their ids */
  answersUpdateInBatch: Maybe<AnswersUpdateInBatchPayload>;
  cloneAutomationTicket: Maybe<TicketsClonePayload>;
  createAutomationFlow: Maybe<FlowsCreatePayload>;
  createAutomationGroup: Maybe<GroupsCreatePayload>;
  createAutomationProperty: Maybe<PropertiesCreatePayload>;
  createAutomationStep: Maybe<StepsCreatePayload>;
  createTemplateTaskUrl: Maybe<CreatePayload>;
  deleteAutomationFlow: Maybe<FlowsDeletePayload>;
  deleteAutomationGroup: Maybe<GroupsDeletePayload>;
  deleteAutomationStep: Maybe<StepsDeletePayload>;
  deleteAutomationTicket: Maybe<TicketsDeletePayload>;
  deleteAutomationTicketInBatch: Maybe<TicketsDeleteInBatchPayload>;
  deleteInBatchPublicTasks: Maybe<DeleteInBatchPublicTasksPayload>;
  deleteTemplateTaskUrl: Maybe<DeletePayload>;
  finishTask: Maybe<FinishTaskPayload>;
  getTask: Maybe<GetTaskPayload>;
  hideTask: Maybe<HideTaskPayload>;
  leaveTask: Maybe<LeaveTaskPayload>;
  startedTask: Maybe<StartedTaskPayload>;
  submitAnswersTemplateTaskUrl: Maybe<SubmitTemplateTaskPayload>;
  templateTaskUrlCreateEmail: Maybe<CreateEmailPayload>;
  templateTaskUrlDeleteEmail: Maybe<DeleteEmailPayload>;
  templateTaskUrlResendEmail: Maybe<ResendEmailPayload>;
  templateTaskUrlSendEmailPdf: Maybe<SendEmailPdfPayload>;
  /** Updates a answer by id */
  updateAnswer: Maybe<AnswerUpdatePayload>;
  updateAutomationFlow: Maybe<FlowsUpdatePayload>;
  updateAutomationGroup: Maybe<GroupsUpdatePayload>;
  updateAutomationStep: Maybe<StepsUpdatePayload>;
  updateTemplateTaskUrl: Maybe<UpdatePayload>;
};


export type MutationAnswersUpdateInBatchArgs = {
  input: AnswersUpdateInBatchInput;
};


export type MutationCloneAutomationTicketArgs = {
  input: TicketsCloneInput;
};


export type MutationCreateAutomationFlowArgs = {
  input: FlowsCreateInput;
};


export type MutationCreateAutomationGroupArgs = {
  input: GroupsCreateInput;
};


export type MutationCreateAutomationPropertyArgs = {
  input: PropertiesCreateInput;
};


export type MutationCreateAutomationStepArgs = {
  input: StepsCreateInput;
};


export type MutationCreateTemplateTaskUrlArgs = {
  input: CreateInput;
};


export type MutationDeleteAutomationFlowArgs = {
  input: FlowsDeleteInput;
};


export type MutationDeleteAutomationGroupArgs = {
  input: GroupsDeleteInput;
};


export type MutationDeleteAutomationStepArgs = {
  input: StepsDeleteInput;
};


export type MutationDeleteAutomationTicketArgs = {
  input: TicketsDeleteInput;
};


export type MutationDeleteAutomationTicketInBatchArgs = {
  input: TicketsDeleteInBatchInput;
};


export type MutationDeleteInBatchPublicTasksArgs = {
  input: DeleteInBatchPublicTasksInput;
};


export type MutationDeleteTemplateTaskUrlArgs = {
  input: DeleteInput;
};


export type MutationFinishTaskArgs = {
  input: FinishTaskInput;
};


export type MutationGetTaskArgs = {
  input: GetTaskInput;
};


export type MutationHideTaskArgs = {
  input: HideTaskInput;
};


export type MutationLeaveTaskArgs = {
  input: LeaveTaskInput;
};


export type MutationStartedTaskArgs = {
  input: StartedTaskInput;
};


export type MutationSubmitAnswersTemplateTaskUrlArgs = {
  input: SubmitTemplateTaskInput;
};


export type MutationTemplateTaskUrlCreateEmailArgs = {
  input: CreateEmailInput;
};


export type MutationTemplateTaskUrlDeleteEmailArgs = {
  input: DeleteEmailInput;
};


export type MutationTemplateTaskUrlResendEmailArgs = {
  input: ResendEmailInput;
};


export type MutationTemplateTaskUrlSendEmailPdfArgs = {
  input: SendEmailPdfInput;
};


export type MutationUpdateAnswerArgs = {
  input: AnswerUpdateInput;
};


export type MutationUpdateAutomationFlowArgs = {
  input: FlowsUpdateInput;
};


export type MutationUpdateAutomationGroupArgs = {
  input: GroupsUpdateInput;
};


export type MutationUpdateAutomationStepArgs = {
  input: StepsUpdateInput;
};


export type MutationUpdateTemplateTaskUrlArgs = {
  input: UpdateInput;
};

export type Option = {
  __typename?: 'Option';
  alertMessage: Maybe<Scalars['String']>;
  answers: Maybe<Array<Answer>>;
  clonedFromId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  externalCreatedAt: Maybe<Scalars['BigInt']>;
  externalUpdatedAt: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  isFlagged: Maybe<Scalars['Boolean']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  noApplyScore: Maybe<Scalars['Boolean']>;
  position: Maybe<Scalars['Int']>;
  question: Maybe<Question>;
  questionId: Maybe<Scalars['ID']>;
  score: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Option. */
export type OptionConnection = {
  __typename?: 'OptionConnection';
  /** A list of edges. */
  edges: Array<OptionEdge>;
  /** A list of nodes. */
  nodes: Array<Option>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OptionEdge = {
  __typename?: 'OptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Option>;
};

/** OrderBy types with names field */
export enum OrderNames {
  /** Order by name asc */
  NameAsc = 'nameAsc',
  /** Order by name desc */
  NameDesc = 'nameDesc'
}

/** OrderBy types with names field */
export enum OrderTaskTickets {
  /** Order by created_at asc */
  CreatedAtAsc = 'createdAtAsc',
  /** Order by created_at desc */
  CreatedAtDesc = 'createdAtDesc',
  /** Order by status asc */
  StatusAsc = 'statusAsc',
  /** Order by status desc */
  StatusDesc = 'statusDesc'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type PaginationAutomationFlowEnum = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PaginationAutomationGroupEnum = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PaginationAutomationPropertyEnum = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PaginationAutomationStepActionEnum = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PaginationAutomationStepEnum = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PaginationAutomationTicketEnum = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PaginationCollectionEnum = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of PropertiesCreate */
export type PropertiesCreateInput = {
  automationFlowId?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  questionId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of PropertiesCreate. */
export type PropertiesCreatePayload = {
  __typename?: 'PropertiesCreatePayload';
  automationProperty: Maybe<Property>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

export type PropertiesInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['ID'];
  position?: InputMaybe<Scalars['Int']>;
  propertyType: AutomationPropertyTypes;
};

export type Property = {
  __typename?: 'Property';
  authorId: Maybe<Scalars['ID']>;
  automationFlow: Maybe<Flow>;
  automationFlowId: Maybe<Scalars['ID']>;
  automationTicketProperties: Maybe<Array<TicketProperty>>;
  automationTicketPropertyAnswers: Maybe<Array<Scalars['JSON']>>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  propertyType: Maybe<Scalars['String']>;
  question: Maybe<Question>;
  questionId: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Query = {
  __typename?: 'Query';
  /** Query Formularies */
  allFormularies: Array<Formulary>;
  /** Query Formularies Groups */
  allFormularyGroups: Array<FormularyGroup>;
  answer: Maybe<Array<Answer>>;
  answersByItem: Maybe<Array<Answer>>;
  automationFlow: Maybe<Flow>;
  automationFlows: Array<Flow>;
  automationGroup: Maybe<Group>;
  automationGroups: Array<Group>;
  automationProperties: Array<Property>;
  automationProperty: Maybe<Property>;
  automationStep: Maybe<Step>;
  automationStepAction: Maybe<StepAction>;
  automationStepActions: Array<StepAction>;
  automationSteps: Array<Step>;
  /** Query Steps */
  automationStepsConnection: StepConnection;
  automationTicket: Maybe<Ticket>;
  automationTicketProperty: Maybe<Flow>;
  automationTicketStep: Maybe<TicketStep>;
  automationTickets: Array<Ticket>;
  collections: Array<Collection>;
  /** Query Exports */
  exports: ExportConnection;
  /** Query FlaggedAnswers */
  flaggedAnswersConnection: AnswerConnection;
  /** Query Formularies */
  formularies: FormularyConnection;
  generateErrorsImport: Maybe<Scalars['JSON']>;
  /** Query Imports */
  imports: ImportConnection;
  locals: Maybe<Array<Local>>;
  /** Query Macro filter */
  macroFilters: MacroFilterConnection;
  /** Query Options */
  options: OptionConnection;
  /** Query Questions */
  publicTasks: Maybe<TaskConnection>;
  publicTemplateTaskUrl: Maybe<TemplateTaskUrl>;
  /** Query Questions */
  questions: QuestionConnection;
  task: Maybe<Task>;
  /** Query TaskTickets */
  taskTickets: TaskConnection;
  teams: Maybe<Array<Team>>;
  templateTaskUrl: Maybe<TemplateTaskUrl>;
  templateTaskUrls: Maybe<Array<TemplateTaskUrl>>;
  totalTicketCount: Scalars['Int'];
  /** Query Users */
  users: UserConnection;
};


export type QueryAllFormulariesArgs = {
  id: InputMaybe<Scalars['ID']>;
  orderBy: InputMaybe<OrderNames>;
  search: InputMaybe<Scalars['String']>;
  withoutGroup: InputMaybe<Scalars['Boolean']>;
};


export type QueryAllFormularyGroupsArgs = {
  orderBy: InputMaybe<OrderNames>;
};


export type QueryAnswerArgs = {
  id: Scalars['String'];
};


export type QueryAnswersByItemArgs = {
  itemId: Scalars['ID'];
};


export type QueryAutomationFlowArgs = {
  id: Scalars['String'];
};


export type QueryAutomationFlowsArgs = {
  filter: InputMaybe<AutomationFlowFilter>;
  orderBy?: InputMaybe<AutomationFlowOrderBy>;
  pagination: InputMaybe<PaginationAutomationFlowEnum>;
};


export type QueryAutomationGroupArgs = {
  id: Scalars['String'];
};


export type QueryAutomationGroupsArgs = {
  filter: InputMaybe<AutomationGroupFilter>;
  orderBy?: InputMaybe<AutomationGroupOrderBy>;
  pagination: InputMaybe<PaginationAutomationGroupEnum>;
};


export type QueryAutomationPropertiesArgs = {
  filter: InputMaybe<AutomationPropertyFilter>;
  orderBy?: InputMaybe<AutomationPropertyOrderBy>;
  pagination: InputMaybe<PaginationAutomationPropertyEnum>;
};


export type QueryAutomationPropertyArgs = {
  id: Scalars['String'];
};


export type QueryAutomationStepArgs = {
  id: Scalars['String'];
};


export type QueryAutomationStepActionArgs = {
  id: Scalars['String'];
};


export type QueryAutomationStepActionsArgs = {
  filter: InputMaybe<AutomationStepActionFilter>;
  orderBy?: InputMaybe<AutomationStepActionOrderBy>;
  pagination: InputMaybe<PaginationAutomationStepActionEnum>;
};


export type QueryAutomationStepsArgs = {
  filter: InputMaybe<AutomationStepFilter>;
  orderBy?: InputMaybe<AutomationStepOrderBy>;
  pagination: InputMaybe<PaginationAutomationStepEnum>;
};


export type QueryAutomationStepsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  automationFlowId: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  onlyProgressing: InputMaybe<Scalars['Boolean']>;
  search: InputMaybe<Scalars['String']>;
};


export type QueryAutomationTicketArgs = {
  id: Scalars['String'];
};


export type QueryAutomationTicketPropertyArgs = {
  id: Scalars['String'];
};


export type QueryAutomationTicketStepArgs = {
  id: Scalars['String'];
};


export type QueryAutomationTicketsArgs = {
  filter: InputMaybe<AutomationTicketFilter>;
  orderBy?: InputMaybe<AutomationTicketOrderBy>;
  pagination: InputMaybe<PaginationAutomationTicketEnum>;
};


export type QueryCollectionsArgs = {
  filter: InputMaybe<CollectionFilter>;
  orderBy?: InputMaybe<CollectionOrderBy>;
  pagination: InputMaybe<PaginationCollectionEnum>;
};


export type QueryExportsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};


export type QueryFlaggedAnswersConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filters: InputMaybe<Scalars['JSON']>;
  first: InputMaybe<Scalars['Int']>;
  id: InputMaybe<Scalars['ID']>;
  last: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<OrderNames>;
  questionId: InputMaybe<Scalars['ID']>;
  search: InputMaybe<Scalars['String']>;
};


export type QueryFormulariesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  id: InputMaybe<Scalars['ID']>;
  last: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<OrderNames>;
  search: InputMaybe<Scalars['String']>;
  withoutGroup: InputMaybe<Scalars['Boolean']>;
};


export type QueryGenerateErrorsImportArgs = {
  importId: Scalars['ID'];
};


export type QueryImportsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};


export type QueryLocalsArgs = {
  active: InputMaybe<Scalars['Boolean']>;
};


export type QueryMacroFiltersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  column: MacroColumnFilter;
  filters: Scalars['JSON'];
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  table: MacroTableFilter;
};


export type QueryOptionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  id: InputMaybe<Scalars['ID']>;
  last: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<OrderNames>;
  questionId: InputMaybe<Scalars['ID']>;
  search: InputMaybe<Scalars['String']>;
};


export type QueryPublicTasksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  status: InputMaybe<TaskTypes>;
};


export type QueryPublicTemplateTaskUrlArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  formularyId: InputMaybe<Scalars['ID']>;
  id: InputMaybe<Scalars['ID']>;
  last: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<OrderNames>;
  parentOptionId: InputMaybe<Scalars['ID']>;
  parentQuestionId: InputMaybe<Scalars['ID']>;
  questionType: InputMaybe<QuestionTypes>;
  questionTypes: InputMaybe<Array<QuestionTypes>>;
  search: InputMaybe<Scalars['String']>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTaskTicketsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  onlyAvailableTasks: InputMaybe<Scalars['Boolean']>;
  onlyMyTasks: InputMaybe<Scalars['Boolean']>;
  onlyTasksWithoutMember: InputMaybe<Scalars['Boolean']>;
  orderBy: InputMaybe<Array<OrderTaskTickets>>;
  search: InputMaybe<Scalars['String']>;
  status: InputMaybe<TaskTypes>;
  statuses: InputMaybe<Array<TaskTypes>>;
  stepIds: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryTemplateTaskUrlArgs = {
  id: Scalars['ID'];
};


export type QueryTemplateTaskUrlsArgs = {
  templateTaskId: Scalars['ID'];
};


export type QueryTotalTicketCountArgs = {
  automationFlowId: Scalars['ID'];
  filter: InputMaybe<AutomationTicketFilter>;
};


export type QueryUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  id: InputMaybe<Scalars['ID']>;
  last: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<OrderNames>;
  search: InputMaybe<Scalars['String']>;
  withoutTeam: InputMaybe<Scalars['Boolean']>;
};

export type Question = {
  __typename?: 'Question';
  actionPlanId: Maybe<Scalars['ID']>;
  authorId: Maybe<Scalars['ID']>;
  childrenQuestionsCount: Maybe<Scalars['Int']>;
  clonedFromId: Maybe<Scalars['ID']>;
  codeMaxChar: Maybe<Scalars['Int']>;
  conditional: Maybe<Scalars['JSON']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  enabled: Maybe<Scalars['Boolean']>;
  externalCreatedAt: Maybe<Scalars['Int']>;
  externalUpdatedAt: Maybe<Scalars['Int']>;
  flaggedOptions: Maybe<Array<Option>>;
  formulary: Maybe<Formulary>;
  formularyId: Maybe<Scalars['ID']>;
  hasComments: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  inputMask: Maybe<Scalars['String']>;
  lastAuthorId: Maybe<Scalars['ID']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  maxPictures: Maybe<Scalars['Int']>;
  meta: Maybe<Scalars['JSON']>;
  name: Maybe<Scalars['String']>;
  options: Maybe<Array<Option>>;
  parentOptionId: Maybe<Scalars['ID']>;
  parentQuestionId: Maybe<Scalars['ID']>;
  position: Maybe<Scalars['Int']>;
  qrCodeOnly: Maybe<Scalars['Boolean']>;
  questionType: Maybe<Scalars['String']>;
  required: Maybe<Scalars['Boolean']>;
  scoreWeight: Maybe<Scalars['Float']>;
  section: Maybe<Section>;
  sectionId: Maybe<Scalars['ID']>;
  taskButtonLabel: Maybe<Scalars['String']>;
  taskCreate: Maybe<Scalars['Boolean']>;
  taskFormularyId: Maybe<Scalars['ID']>;
  tipText: Maybe<Scalars['String']>;
  topics: Maybe<Array<Topic>>;
  updatedAt: Scalars['ISO8601DateTime'];
  validationMinChar: Maybe<Scalars['Int']>;
  validationUrl: Maybe<Scalars['String']>;
};

/** The connection type for Question. */
export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  /** A list of edges. */
  edges: Array<QuestionEdge>;
  /** A list of nodes. */
  nodes: Array<Question>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Question>;
};

/** Question types enum */
export enum QuestionTypes {
  /** Plano de Ação */
  ActionPlan = 'action_plan',
  /** Código de Barra */
  Barcode = 'barcode',
  /** Placa de Carro */
  CarPlate = 'car_plate',
  /** Múltipla Escolha */
  Checkbox = 'checkbox',
  /** Múltipla Escolha (combo) */
  CheckboxCombo = 'checkbox_combo',
  /** Gerar código aleatório */
  CodeGenerator = 'code_generator',
  /** Coleções */
  Collections = 'collections',
  /** Combo de Coleções */
  CollectionsCombo = 'collections_combo',
  /** Data */
  Date = 'date',
  /** Data SLA */
  DateSla = 'date_sla',
  /** Decimal */
  Decimal = 'decimal',
  /** Galeria de Fotos */
  Gallery = 'gallery',
  /** Geolocalização */
  Geolocation = 'geolocation',
  /** Inteiro */
  Integer = 'integer',
  /** Moeda */
  Money = 'money',
  /** Parágrafo */
  Paragraph = 'paragraph',
  /** Foto */
  Picture = 'picture',
  /** Única Escolha */
  Radio = 'radio',
  /** Única Escolha (combo) */
  RadioCombo = 'radio_combo',
  /** Assinatura Eletrônica */
  Signature = 'signature',
  /** Criar subtarefa */
  TaskButton = 'task_button',
  /** Texto */
  Text = 'text',
  /** Parágrafo */
  Textarea = 'textarea',
  /** Hora */
  TimePicker = 'time_picker',
  /** Usuário */
  User = 'user'
}

/** Autogenerated input type of ResendEmail */
export type ResendEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ResendEmail. */
export type ResendEmailPayload = {
  __typename?: 'ResendEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  templateTaskUrlEmail: Maybe<TemplateTaskUrlEmail>;
};

export type Section = {
  __typename?: 'Section';
  authorId: Maybe<Scalars['ID']>;
  clonedFromId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  enabled: Maybe<Scalars['Boolean']>;
  externalCreatedAt: Maybe<Scalars['BigInt']>;
  externalUpdatedAt: Maybe<Scalars['BigInt']>;
  formularyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  lastAuthorId: Maybe<Scalars['ID']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  questions: Maybe<Array<Question>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of SendEmailPdf */
export type SendEmailPdfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  eventId: Scalars['ID'];
};

/** Autogenerated return type of SendEmailPdf. */
export type SendEmailPdfPayload = {
  __typename?: 'SendEmailPdfPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  eventId: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of StartedTask */
export type StartedTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

/** Autogenerated return type of StartedTask. */
export type StartedTaskPayload = {
  __typename?: 'StartedTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  task: Maybe<Task>;
};

export type StatusType = {
  __typename?: 'StatusType';
  color: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  isDefault: Maybe<Scalars['Boolean']>;
  label: Maybe<Scalars['String']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  position: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Step = {
  __typename?: 'Step';
  actionEmailChecklist: Maybe<Array<ActionEmailChecklist>>;
  actionEmailUser: Maybe<Array<ActionEmailUser>>;
  actionPropertyUpdate: Maybe<Array<ActionPropertyUpdate>>;
  actionTask: Maybe<ActionTask>;
  actionWebhooks: Maybe<Array<ActionWebhook>>;
  authorId: Maybe<Scalars['ID']>;
  autoFinish: Maybe<Scalars['Boolean']>;
  automationFlow: Maybe<Flow>;
  automationFlowId: Maybe<Scalars['ID']>;
  automationStepActions: Maybe<Array<StepAction>>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  stepType: Maybe<StepTypes>;
  triggers: Maybe<Array<Triggers>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type StepAction = {
  __typename?: 'StepAction';
  actionType: Maybe<Scalars['String']>;
  automationStep: Maybe<Step>;
  automationStepId: Maybe<Scalars['ID']>;
  automationTicketSteps: Maybe<Array<TicketStep>>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  metadata: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Step. */
export type StepConnection = {
  __typename?: 'StepConnection';
  /** A list of edges. */
  edges: Array<StepEdge>;
  /** A list of nodes. */
  nodes: Array<Step>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StepEdge = {
  __typename?: 'StepEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Step>;
};

/** Step types enum */
export enum StepTypes {
  /** Cancelado */
  Canceling = 'canceling',
  /** Criação de Ticket */
  CreateTicket = 'create_ticket',
  /** Finalização */
  Finishing = 'finishing',
  /** Abertura */
  Opening = 'opening',
  /** Progresso */
  Progressing = 'progressing'
}

/** Autogenerated input type of StepsCreate */
export type StepsCreateInput = {
  actionEmailChecklist?: InputMaybe<Array<ActionEmailChecklistInput>>;
  actionEmailUser?: InputMaybe<Array<ActionEmailUserInput>>;
  actionPropertyUpdate?: InputMaybe<Array<ActionPropertyUpdateInput>>;
  actionTask?: InputMaybe<ActionTaskInput>;
  actionWebhooks?: InputMaybe<Array<ActionWebhookInput>>;
  autoFinish?: InputMaybe<Scalars['Boolean']>;
  automationFlowId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Int']>;
  stepType: StepTypes;
  triggers?: InputMaybe<Array<TriggersInput>>;
};

/** Autogenerated return type of StepsCreate. */
export type StepsCreatePayload = {
  __typename?: 'StepsCreatePayload';
  automationStep: Maybe<Step>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of StepsDelete */
export type StepsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of StepsDelete. */
export type StepsDeletePayload = {
  __typename?: 'StepsDeletePayload';
  automationStep: Maybe<Step>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of StepsUpdate */
export type StepsUpdateInput = {
  actionEmailChecklist?: InputMaybe<Array<ActionEmailChecklistInput>>;
  actionEmailUser?: InputMaybe<Array<ActionEmailUserInput>>;
  actionPropertyUpdate?: InputMaybe<Array<ActionPropertyUpdateInput>>;
  actionTask?: InputMaybe<ActionTaskInput>;
  actionWebhooks?: InputMaybe<Array<ActionWebhookInput>>;
  autoFinish?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  stepType?: InputMaybe<StepTypes>;
  triggers?: InputMaybe<Array<TriggersInput>>;
};

/** Autogenerated return type of StepsUpdate. */
export type StepsUpdatePayload = {
  __typename?: 'StepsUpdatePayload';
  automationStep: Maybe<Step>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of SubmitTemplateTask */
export type SubmitTemplateTaskInput = {
  answers: Array<AnswersInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  templateTaskUrlId: Scalars['ID'];
};

/** Autogenerated return type of SubmitTemplateTask. */
export type SubmitTemplateTaskPayload = {
  __typename?: 'SubmitTemplateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  event: Maybe<Event>;
};

export type Task = {
  __typename?: 'Task';
  active: Maybe<Scalars['Boolean']>;
  answersMediaTicket: Maybe<Array<Answer>>;
  authorId: Maybe<Scalars['ID']>;
  autoFinish: Maybe<Scalars['Boolean']>;
  automationFlowId: Maybe<Scalars['ID']>;
  automationStep: Maybe<Step>;
  automationTicket: Maybe<Ticket>;
  automationTicketId: Maybe<Scalars['ID']>;
  automationTicketStep: Maybe<TicketStep>;
  automationTicketStepId: Maybe<Scalars['ID']>;
  createOrigin: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  dateEnd: Maybe<Scalars['BigInt']>;
  dateStart: Maybe<Scalars['BigInt']>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  event: Maybe<Event>;
  eventId: Maybe<Scalars['ID']>;
  externalCreatedAt: Maybe<Scalars['BigInt']>;
  externalUpdatedAt: Maybe<Scalars['BigInt']>;
  finishedAt: Maybe<Scalars['BigInt']>;
  formulary: Maybe<Formulary>;
  formularyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  importId: Maybe<Scalars['ID']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  localId: Maybe<Scalars['ID']>;
  member: Maybe<User>;
  memberId: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  originId: Maybe<Scalars['ID']>;
  originProperty: Maybe<Scalars['String']>;
  originType: Maybe<Scalars['String']>;
  parentEventId: Maybe<Scalars['ID']>;
  parentItemId: Maybe<Scalars['ID']>;
  parentQuestionId: Maybe<Scalars['ID']>;
  parentTaskId: Maybe<Scalars['ID']>;
  populateFromAttributes: Maybe<Scalars['Boolean']>;
  publicTask: Maybe<Scalars['Boolean']>;
  status: Maybe<TaskTypes>;
  taskAttributeValues: Maybe<Array<TaskAttributeValue>>;
  tasksTicket: Maybe<Array<Task>>;
  templateTask: Maybe<TemplateTask>;
  templateTaskId: Maybe<Scalars['ID']>;
  templateTaskUrl: Maybe<TemplateTaskUrl>;
  templateUrlEmail: Maybe<TemplateTaskUrlEmail>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TaskAttribute = {
  __typename?: 'TaskAttribute';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
};

export type TaskAttributeValue = {
  __typename?: 'TaskAttributeValue';
  content: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  optionIds: Maybe<Array<Scalars['ID']>>;
  position: Maybe<Scalars['Int']>;
  targetQuestionId: Maybe<Scalars['ID']>;
  targetQuestionType: Maybe<Scalars['String']>;
  taskAttribute: Maybe<TaskAttribute>;
  taskAttributeId: Maybe<Scalars['ID']>;
  taskId: Maybe<Scalars['ID']>;
};

/** The connection type for Task. */
export type TaskConnection = {
  __typename?: 'TaskConnection';
  /** A list of edges. */
  edges: Array<TaskEdge>;
  /** A list of nodes. */
  nodes: Array<Task>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TaskEdge = {
  __typename?: 'TaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Task>;
};

/** Task types enum */
export enum TaskTypes {
  /** Desativada */
  Disabled = 'disabled',
  /** Finalizada */
  Done = 'done',
  /** Em andamento */
  Draft = 'draft',
  /** Pendente */
  Pending = 'pending'
}

export type Team = {
  __typename?: 'Team';
  authorId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  users: Maybe<Array<User>>;
};

export type TemplateTask = {
  __typename?: 'TemplateTask';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  dueIn: Maybe<Scalars['BigInt']>;
  formulary: Maybe<Formulary>;
  formularyId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  local: Maybe<Local>;
  localId: Maybe<Scalars['ID']>;
  member: Maybe<User>;
  memberId: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  qrCode: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TemplateTaskUrl = {
  __typename?: 'TemplateTaskUrl';
  active: Maybe<Scalars['Boolean']>;
  authorId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  templateTask: Maybe<TemplateTask>;
  templateTaskId: Maybe<Scalars['ID']>;
  templateTaskUrlEmails: Maybe<Array<TemplateTaskUrlEmail>>;
  updatedAt: Scalars['ISO8601DateTime'];
  urlType: Maybe<TemplateUrlTypes>;
};

export type TemplateTaskUrlEmail = {
  __typename?: 'TemplateTaskUrlEmail';
  author: Maybe<User>;
  authorId: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  sentAt: Maybe<Scalars['ISO8601DateTime']>;
  templateTaskUrl: Maybe<TemplateTaskUrl>;
  templateTaskUrlId: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Step types enum */
export enum TemplateUrlTypes {
  /** Uso único */
  OnlyUse = 'only_use',
  /** Recorrente */
  Recurrency = 'recurrency'
}

export type Ticket = {
  __typename?: 'Ticket';
  author: Maybe<User>;
  automationFlow: Maybe<Flow>;
  automationFlowId: Maybe<Scalars['ID']>;
  automationTicketProperties: Maybe<Array<TicketProperty>>;
  automationTicketSteps: Maybe<Array<TicketStep>>;
  automationTicketStepsTasks: Maybe<Array<TicketStep>>;
  clonedFromId: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customCode: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  event: Maybe<Event>;
  eventId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  status: Maybe<Scalars['String']>;
  tasks: Maybe<Array<Task>>;
  ticketCode: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TicketProperty = {
  __typename?: 'TicketProperty';
  answer: Maybe<Answer>;
  answerId: Maybe<Scalars['ID']>;
  automationProperty: Maybe<Property>;
  automationPropertyId: Maybe<Scalars['ID']>;
  automationTicket: Maybe<Ticket>;
  automationTicketId: Maybe<Scalars['ID']>;
  clonedFromId: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TicketStep = {
  __typename?: 'TicketStep';
  author: Maybe<User>;
  automationStepAction: Maybe<StepAction>;
  automationStepActionId: Maybe<Scalars['ID']>;
  automationStepId: Maybe<Scalars['ID']>;
  automationTicket: Maybe<Ticket>;
  automationTicketId: Maybe<Scalars['ID']>;
  clonedFromId: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  errors: Maybe<Scalars['Int']>;
  event: Maybe<Event>;
  id: Scalars['ID'];
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  status: Maybe<Scalars['String']>;
  task: Maybe<Task>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of TicketsClone */
export type TicketsCloneInput = {
  automationTicketId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notCloneStepIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of TicketsClone. */
export type TicketsClonePayload = {
  __typename?: 'TicketsClonePayload';
  automationTicket: Maybe<Ticket>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of TicketsDeleteInBatch */
export type TicketsDeleteInBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['String']>;
};

/** Autogenerated return type of TicketsDeleteInBatch. */
export type TicketsDeleteInBatchPayload = {
  __typename?: 'TicketsDeleteInBatchPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  tickets: Maybe<Array<Ticket>>;
};

/** Autogenerated input type of TicketsDelete */
export type TicketsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Autogenerated return type of TicketsDelete. */
export type TicketsDeletePayload = {
  __typename?: 'TicketsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  ticket: Maybe<Ticket>;
};

export type Topic = {
  __typename?: 'Topic';
  authorId: Maybe<Scalars['ID']>;
  commentsCount: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  discussionStatusTypeId: Maybe<Scalars['ID']>;
  eventAuthorId: Maybe<Scalars['ID']>;
  eventId: Maybe<Scalars['ID']>;
  eventParentableId: Maybe<Scalars['String']>;
  eventParentableType: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemId: Maybe<Scalars['ID']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  parentableId: Maybe<Scalars['ID']>;
  parentableType: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  reviewOn: Maybe<Scalars['Boolean']>;
  status: Maybe<Scalars['String']>;
  statusType: Maybe<StatusType>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Trigger types enum */
export enum TriggerableTypes {
  /** Formulário */
  Formulary = 'Formulary',
  /** Opção */
  Option = 'Option',
  /** Pergunta */
  Question = 'Question',
  /** Etapa de Automação */
  Step = 'Step'
}

export type Triggers = {
  __typename?: 'Triggers';
  dateQuestion: Maybe<Question>;
  dateQuestionId: Maybe<Scalars['ID']>;
  delayType: Maybe<DelayTypeTriggers>;
  id: Scalars['ID'];
  name: Maybe<Scalars['ID']>;
  triggerableId: Scalars['ID'];
  triggerableName: Scalars['String'];
  triggerableType: TriggerableTypes;
};

export type TriggersInput = {
  acceptNull?: InputMaybe<Scalars['Boolean']>;
  dateQuestionId?: InputMaybe<Scalars['ID']>;
  delayType?: InputMaybe<DelayTypeTriggers>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['ID'];
  triggerableId: Scalars['ID'];
  triggerableType: TriggerableTypes;
};

/** Autogenerated input type of Update */
export type UpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailsDirect?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  urlType?: InputMaybe<TemplateUrlTypes>;
};

/** Autogenerated return type of Update. */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  templateTaskUrl: Maybe<TemplateTaskUrl>;
};

export type User = {
  __typename?: 'User';
  active: Maybe<Scalars['Boolean']>;
  avatarUrl: Maybe<Scalars['String']>;
  charge: Maybe<Scalars['String']>;
  confirmedAt: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']>;
  document: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstLogin: Maybe<Scalars['Boolean']>;
  fullName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importId: Maybe<Scalars['ID']>;
  isOwner: Maybe<Scalars['Boolean']>;
  lastModifiedAt: Maybe<Scalars['BigInt']>;
  lastResetPasswordAt: Maybe<Scalars['ISO8601DateTime']>;
  limitConnections: Maybe<Scalars['Int']>;
  lockedAt: Maybe<Scalars['ISO8601DateTime']>;
  maxChildren: Maybe<Scalars['Int']>;
  passwordDigest: Maybe<Scalars['String']>;
  permissions: Maybe<Scalars['JSON']>;
  phone: Maybe<Scalars['String']>;
  readOtherProfiles: Maybe<Scalars['Boolean']>;
  resetPasswordSentAt: Maybe<Scalars['ISO8601DateTime']>;
  resetPasswordTime: Maybe<Scalars['Int']>;
  resetPasswordToken: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userOrigin: Maybe<Scalars['String']>;
  userType: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
  uuidDevices: Maybe<Scalars['String']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<User>;
};
