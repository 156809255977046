import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useTasks } from '@/screens/registrations/Tasks/Dashboard/hooks/tasks';
import { DownloadIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { FiChevronDown, FiTrash } from 'react-icons/fi';

import { deleteTasksService } from '../../../../../services/deleteTasksService';
import { updateTasksService } from '../../../../../services/updateTasksService';

const ActionsMenu: React.FC = () => {
  const { t } = useTranslation();
  const { checkPermission } = useUserPermissions();
  const { refresh, selectedIds, exportBatchTasks } = useTasks();
  const isDeleting = React.useRef(false);
  const isEnabling = React.useRef(false);
  const isDisabling = React.useRef(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleDelete = React.useCallback(async () => {
    if (!isDeleting.current) {
      isDeleting.current = true;

      const success = await deleteTasksService(selectedIds);
      if (success) {
        refresh();
      }
      isDeleting.current = false;
    }
  }, [refresh, selectedIds]);

  const handleEnable = React.useCallback(async () => {
    if (!isEnabling.current) {
      isEnabling.current = true;
      const success = await updateTasksService(selectedIds, { active: true });
      if (success) {
        refresh();
      }
      isEnabling.current = false;
    }
  }, [selectedIds, refresh]);

  const handleDisable = React.useCallback(async () => {
    if (!isDisabling.current) {
      isDisabling.current = true;
      const success = await updateTasksService(selectedIds, { active: false });
      if (success) {
        refresh();
      }
      isDisabling.current = false;
    }
  }, [selectedIds, refresh]);

  const ConfirmModal = () => (
    <Modal
      isOpen={confirmModalOpen}
      onClose={() => setConfirmModalOpen(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Realmente deseja executar esta ação?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Todas as tarefas selecionadas serão deletadas permanentemente.
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              onClick={() => {
                handleDelete();
                setConfirmModalOpen(false);
              }}
            >
              Confirmar
            </Button>
            <Button onClick={() => setConfirmModalOpen(false)}>Cancelar</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Menu>
      <MenuButton
        colorScheme="brand"
        color="white"
        rightIcon={<Icon as={FiChevronDown} />}
        as={Button}
        isDisabled={selectedIds.length === 0 || !checkPermission('rw')}
      >
        {t('common.actions')}
      </MenuButton>
      <ConfirmModal />
      <MenuList zIndex={20}>
        <MenuItem icon={<BsEyeFill />} onClick={handleEnable}>
          {t('tasks.actions.enable_selected')}
        </MenuItem>
        <MenuItem icon={<BsEyeSlashFill />} onClick={handleDisable}>
          {t('tasks.actions.disable_selected')}
        </MenuItem>
        <MenuItem
          icon={<FiTrash />}
          onClick={() => setConfirmModalOpen(true)}
          isDisabled={!checkPermission('admin')}
        >
          {t('tasks.actions.delete_selected')}
        </MenuItem>
        <MenuItem
          disabled={!!selectedIds.length}
          icon={<DownloadIcon />}
          onClick={() => exportBatchTasks()}
        >
          {t('tasks.actions.export_batch')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ActionsMenu;
