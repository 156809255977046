import { Socket } from '@shared/adapters';
import { Listener } from '@shared/data/protocols/websocket/ISocketClient';
import React, { createContext, useCallback, useContext } from 'react';

import { ActionCableContextData, ChannelEnum } from './interfaces/actionCable';

const ActionCableContext = createContext<ActionCableContextData>(
  {} as ActionCableContextData,
);

export const ActionCableProvider: React.FC = ({ children }) => {
  const subscribe = useCallback(
    (channel: ChannelEnum, listener: Listener<any>, token?: string) => {
      const { socket } = Socket;
      function unsubscribe() {
        if (socket) {
          console.log('unsubcribe', Socket.channels[channel], token);
          socket.off({ channel: Socket.channels[channel], token });
        }
      }
      if (socket) {
        console.log('subscribe', Socket.channels[channel], token);
        socket.on({ channel: Socket.channels[channel], token }, listener);
      }
      return unsubscribe;
    },
    [],
  );

  return (
    <ActionCableContext.Provider value={{ subscribe }}>
      {children}
    </ActionCableContext.Provider>
  );
};

export function useActionCable(): ActionCableContextData {
  const context = useContext(ActionCableContext);

  if (!context) {
    throw new Error(
      'useActionCable must be used whithin an ActionCableProvider',
    );
  }

  return context;
}
