/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/rules-of-hooks */
import { useChecklistSummaryDrawer } from '@/components_v2/ChecklistSummary';
import { Task } from '@/gql/types';
import { Button, HStack, Tag, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';

export const getTableColumns = () => {
  const columns: ColumnDef<Task>[] = [
    {
      accessorKey: 'name',
      header: 'Título',
      cell({ getValue, row }) {
        const { openDrawer } = useChecklistSummaryDrawer();
        const { id } = row.original;
        return (
          <Button
            onClick={() =>
              openDrawer({
                id,
                type: 'task',
                refresh() {
                  //
                },
              })
            }
            size="lg"
            color="blue.600"
            variant="link"
          >
            <Text noOfLines={2}>{getValue()}</Text>
          </Button>
        );
      },
    },
    {
      header: 'Email',
      accessorKey: 'templateUrlEmail',
      cell({ getValue }) {
        const value = getValue<Task['templateUrlEmail']>();

        return <Text>{value ? value?.email : '[sem email]'}</Text>;
      },
    },
    {
      accessorKey: 'status',
      cell() {
        return (
          <HStack>
            <Tag size="md" colorScheme="green" variant="subtle">
              Finalizada
            </Tag>
          </HStack>
        );
      },
    },
    {
      accessorKey: 'templateUrlEmail',
      header: 'Autor',
      cell({ getValue }) {
        const value = getValue<Task['templateUrlEmail']>();
        return (
          <Text noOfLines={3}>{value?.author?.fullName || '[sem autor]'}</Text>
        );
      },
    },
    {
      accessorKey: 'templateTask',
      header: 'Checklist',
      cell({ getValue }) {
        const value = getValue<Task['templateTask']>();
        return <Text noOfLines={3}>{value?.formulary?.name}</Text>;
      },
    },
    {
      header: 'Finalizado em',
      accessorKey: 'finishedAt',
      cell({ cell }) {
        const value = cell.getValue<number>();

        return value ? (
          <Text>{moment(new Date(Number(value))).calendar()}</Text>
        ) : (
          <></>
        );
      },
    },
  ];
  return columns;
};
