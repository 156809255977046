import { FeedbackError } from '@/components/Feedback';
import {
  Collection,
  CollectionItem,
} from '@/modules/core/domain/interfaces/ICollection';
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FiX } from 'react-icons/fi';

import { CollectionItemInputProps } from './CollectionItemInput.types';
import CollectionsList from './components/CollectionsList';
import ItemsList from './components/ItemsList';

const CollectionItemInput: React.FC<CollectionItemInputProps> = ({
  onChange,
  initialItem,
  queryKey,
}) => {
  const [prevItem, setPrevItem] = React.useState(initialItem);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [collection, setCollection] = React.useState<Collection | undefined>();
  const [item, setItem] = React.useState<CollectionItem | undefined>();

  const headerText = React.useMemo(() => {
    if (item && collection) {
      return `${collection.name} > ${item.name}`;
    }
    if (collection) {
      return `${collection.name} > Selecione um item`;
    }
    return 'Selecione uma coleção';
  }, [collection, item]);

  const handleDeselectPrev = React.useCallback(() => {
    setPrevItem(undefined);
    onChange(undefined);
  }, [onChange]);

  const handleSave = React.useCallback(() => {
    if (item && collection) {
      onChange({ item, collection });
      onClose();
    } else {
      FeedbackError({ mainText: 'Selecione um item primeiro' });
    }
  }, [onChange, item, collection, onClose]);

  return (
    <>
      {prevItem && !item ? (
        <Button
          onClick={handleDeselectPrev}
          rightIcon={<Icon as={FiX} />}
          colorScheme="red"
        >
          {prevItem.name}
        </Button>
      ) : (
        <Button
          onClick={onOpen}
          colorScheme={item && initialItem ? 'orange' : 'brand'}
        >
          {item && initialItem ? item.name : 'Selecionar item de coleção'}
        </Button>
      )}

      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{headerText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack alignItems="start" w="full">
              <CollectionsList
                queryKey={queryKey}
                selected={collection}
                onSelect={(collection) => {
                  setCollection(collection);
                  setItem(undefined);
                }}
              />
              <ItemsList
                collectionId={collection ? collection.id : undefined}
                selected={item}
                onSelect={setItem}
              />
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} variant="ghost" onClick={onClose}>
              Fechar
            </Button>
            <Button onClick={handleSave} colorScheme="brand">
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CollectionItemInput;
