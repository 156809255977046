import { createGlobalStyle } from 'styled-components';

import 'flag-icons/css/flag-icons.min.css';

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  img {
    display: inline-block;
  }

   @media (max-width: 1024px) {
    .x-large-font-md {
      font-size: 2.5vw;
    }
    .large-font-md {
      font-size: 2vw;
    }
    .medium-font-md {
      font-size: 1.4vw;
    }
    .small-font-md {
      font-size: 1vw;
    }
    .hidden-md {
      visibility: hidden;
    }
  }

  @media (max-width: 600px) {
    .x-large-font-sm {
      font-size: 3.5vw;
    }
    .large-font-sm {
      font-size: 2.75vw;
    }
    .medium-font-sm {
      font-size: 1.6vw;
    }
    .small-font-sm {
      font-size: 1.2vw;
    }
    .x-large-font-center-sm {
      font-size: 3.5vw;
      text-align: center;
    }
    .center-sm {
      justify-content: center;
    }
  }

  .visibility-parent .visible-on-hover {
    visibility: hidden;
  }

  .visibility-parent:hover .visible-on-hover {
    visibility: visible;
  }

  .scrollShadow {
    background:
      linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }

  .tooltip:hover .tooltiptext {
    display: block;
  }

  .hidden {
    visibility: hidden;
  }

  .chakra-collapse{
    overflow: visible !important;
  }

  :root {
    body > #root {
      height: 100vh;
      display: flex;
      background-color: #ffffff;
      font-family: Nunito Sans;
      text-rendering: optimizedlegibility;
      -webkit-font-smoothing: antialiased;
    }

    font-size: 12px;

  }

  .pac-logo { // Google Places Logo removal
    ::after {content: none;}
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    /* border-radius: 3px; */
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) =>
      !props.theme ? '#ebebeb' : props.theme.colors.primary};
    /* border-radius: 3px; */
  }

  .popup-content {
    font-family: ${(props) =>
      props.theme ? props.theme.fonts.primaryFont : 'Poppins'};
  }

  .label-custom-show {
    display: block;
  }
  .label-custom-hidden {
    display: none;
  }
` as any;

export default Global;
