import { DeeplinkScreen } from '@/screens/Deeplink';
import { ExternalTaskPage } from '@/screens/ExternalTask/pages/ExternalTaskPage';
import EventReportsRoute from '@/screens/monitoring/EventReports/Route';
import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';

const ExternalRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/event/:id`} component={EventReportsRoute} />
      <Route path={`${path}/deep_link`} component={DeeplinkScreen} />
      <Route path={`${path}/task/:id`} component={ExternalTaskPage} />
    </Switch>
  );
};

export default ExternalRoutes;
