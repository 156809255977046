import React, { useState, useRef, useEffect } from 'react';
import debounce from '@/utils/debounce';
import { Input } from '@chakra-ui/react';
import { DebouncedInputProps } from './DebouncedInput.types';

const DebouncedInput: React.FC<DebouncedInputProps> = ({
  onChange,
  initialValue,
  ...rest
}) => {
  const [value, setValue] = useState<string>(initialValue || '');

  const valueRef = useRef<string>();
  const [debounced] = useState(() => {
    return debounce(async () => {
      if (valueRef.current !== undefined) {
        onChange(valueRef.current);
      }
    }, 200);
  });

  useEffect(() => {
    valueRef.current = value;
    debounced.cancel();
    debounced();
  }, [debounced, value]);

  return (
    <Input value={value} onChange={(e) => setValue(e.target.value)} {...rest} />
  );
};

export default DebouncedInput;
