import React from 'react';
import {
  Switch,
  RouteProps,
  useRouteMatch,
  Redirect,
  Route,
} from 'react-router-dom';
import { useWorkspace } from '@hooks/workspace';
import { SignIn, Workspace } from '@screens/Authentication';

import PasswordRecovery from './groups/passwordRecovery.routes';

const AuthRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();
  const { validWorkspace, loading: isLoading } = useWorkspace();

  if (isLoading) return null;

  return (
    <Switch>
      <Route path={`${path}/workspace`} component={Workspace} />
      <Route path={`${path}/signin`} component={SignIn} />
      <Route path={`${path}/recovery`} component={PasswordRecovery} />
      <Redirect to={validWorkspace ? `${path}/signin` : `${path}/workspace`} />
    </Switch>
  );
};

export default AuthRoutes;
