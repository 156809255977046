import { linkify } from '@/utils/linkify';
import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { TooltipProps } from './localGeneric';

export const ToolTip = styled(Popup)<{ lightContent?: boolean }>`
  &-content {
    background-color: ${(props) =>
      props.lightContent ? 'white' : 'rgba(51, 51, 51, 0.9)'};
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    font-size: 12px;
    ${({ position }) => {
      if (position === 'top right') return 'transform: translateX(13px);';
      return null;
    }}
  }
  &-arrow {
    color: ${(props) =>
      props.lightContent ? 'white' : 'rgba(51, 51, 51, 0.9)'};
    ${({ position }) => {
      if (position === 'top right') return 'transform: translateX(10px);';
      return null;
    }}
  }
`;

const TooltipComponent: React.FC<TooltipProps> = ({
  children,
  description,
  placement,
  lightContent = false,
}) => {
  const linkedDescription = description ? linkify(description) : null;

  return (
    <ToolTip
      lightContent={lightContent}
      on={['hover']}
      position={placement}
      trigger={children}
      closeOnDocumentClick
      mouseEnterDelay={200}
      contentStyle={{ zIndex: 9999 }}
    >
      {!!linkedDescription && (
        <p style={{ color: lightContent ? 'black' : 'white', display: 'flex' }}>
          {linkedDescription.map((part) =>
            part.type === 'link' ? (
              <a
                key={part.key}
                href={part.value}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '2px', marginRight: '2px' }}
              >
                {part.value}
              </a>
            ) : (
              <p key={part.key}>{part.value}</p>
            ),
          )}
        </p>
      )}
    </ToolTip>
  );
};

export default TooltipComponent;
