import styled from 'styled-components';
import { Tabs as TabsComponent } from 'react-tabs';

export const Container = styled.div`
  overflow: hidden;
  max-height: 100%;
`;

export const Tabs = styled(TabsComponent)`
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .react-tabs__tab-list {
    border-bottom: 1px solid #c4c4c4;
    padding: 0 10px;
  }

  .react-tabs__tab {
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
    padding: 4px 8px;
    color: ${(props) => props.theme.colors.darkText};
    border-bottom: transparent;
    cursor: pointer;
    &:focus {
      z-index: 1;
      box-shadow: outline;
    }
  }

  .react-tabs__tab--selected {
    border-bottom: 2px solid ${(props) => props.theme.colors.primaryV2};
    color: ${(props) => props.theme.colors.primaryV2};
  }

  .react-tabs__tab--disabled {
    opacity: 0.4;
    cursor: 'not-allowed';
  }

  .react-tabs__tab-panel {
    max-height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
  }
  /*
.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}



.react-tabs__tab-panel--selected {
  display: block;
} */
`;
