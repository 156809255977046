import { useFragment } from '@/gql/fragment-masking';
import {
  AutomationFlowProvider,
  useAutomationFlow,
  useAutomationFlowProvider,
} from '@/screens/Tickets/context/automationFlow';
import { CtaCard } from '@/screens/Tickets/Dashboard/components/CtaCard';
import React from 'react';

import { withTicketLayout } from '../Common';
import { useCurrentAutoFlow } from '../hooks/useCurrentAutoFlow';
import { AutomationFlowPropsFrag } from '../hooks/useFetchFlow';
import BoardTicket from './components/BoardTicket';
import { Header } from './components/Header';

const TicketDashboard: React.FC = () => {
  const { flow } = useAutomationFlow();
  const flowFragment = useFragment(AutomationFlowPropsFrag, flow);

  const hasData =
    !!flowFragment?.automationProperties?.length ||
    !!flowFragment?.automationSteps?.length;

  return (
    <>
      <Header />
      {hasData ? <BoardTicket /> : <CtaCard id={`${flowFragment?.id}`} />}
    </>
  );
};

const WrappedTicketDashboard: React.FC = () => {
  const { id } = useCurrentAutoFlow();

  const value = useAutomationFlowProvider(id);
  if (!id) return <></>;

  return (
    <AutomationFlowProvider value={value}>
      <TicketDashboard />
    </AutomationFlowProvider>
  );
};

export default withTicketLayout(WrappedTicketDashboard);
