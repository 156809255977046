/* eslint-disable no-underscore-dangle */
import {
  BaseField,
  DisplayIf,
  Field,
  FormLayout,
  HookForm,
} from '@/components_v2/HookForm';
import { QuestionTypes, TriggerableTypes } from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import { Button, chakra } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  AutomationStepsSelect,
  FormularySelect,
  OptionSelect,
  QuestionSelect,
} from '../../Selects';
import { triggerSchema, TriggerSchema } from '../form-schemas';
import { Callout } from '../misc';

const triggerableTypes = [
  { value: '', label: 'Selecione' },
  { value: TriggerableTypes.Formulary, label: 'Formulário' },
  { value: TriggerableTypes.Question, label: 'Pergunta' },
  { value: TriggerableTypes.Option, label: 'Opção' },
  { value: TriggerableTypes.Step, label: 'Etapa' },
];

function Fields({ automationFlowId }: { automationFlowId: string }) {
  const form = useFormContext<TriggerSchema>();

  const triggerable = form.watch('triggerable');
  const triggerableType = form.watch('triggerableType');

  return (
    <FormLayout padding={1} spacing={5} flexGrow={0} w="full" overflow="hidden">
      <Callout>
        <chakra.p fontSize="xl" fontWeight="bold">
          Como usar?
        </chakra.p>
        Através das ativações, você irá configurar as formas que esta etapa será
        iniciada.{' '}
        <strong>
          A etapa pode ser ativada por um formulário, pergunta ou opção
          específica.
        </strong>
      </Callout>
      <Field<TriggerSchema>
        name="triggerableType"
        label="Tipo de ativação"
        type="native-select"
        defaultValue=""
        onChange={() => {
          form.resetField('triggerable');
        }}
        options={triggerableTypes}
      />

      <chakra.div>
        <DisplayIf<TriggerSchema>
          name="triggerableType"
          condition={(value) => value === TriggerableTypes.Formulary}
        >
          <Controller
            name="triggerable"
            control={form.control}
            shouldUnregister
            render={({ field }) => (
              <BaseField
                name="triggerable"
                label="Formulário da ativação"
                flexGrow={0}
              >
                <FormularySelect
                  selectionMode="single"
                  value={field.value?.map((e) => e.id) as any}
                  initialOptions={(field.value || []) as any}
                  onChange={(keys) => {
                    field.onChange(keys);
                  }}
                />
              </BaseField>
            )}
          />
        </DisplayIf>

        <DisplayIf<TriggerSchema>
          name="triggerableType"
          condition={(value) => value === TriggerableTypes.Question}
        >
          <Controller
            name="triggerable"
            control={form.control}
            shouldUnregister
            render={({ field }) => (
              <BaseField
                name="triggerable"
                label="Pergunta de ativação"
                flexGrow={0}
              >
                <QuestionSelect
                  selectionMode="single"
                  value={field.value?.map((e) => e?.id) as any}
                  initialOptions={(field.value || []) as any}
                  onChange={(keys) => {
                    field.onChange(keys);
                  }}
                />
              </BaseField>
            )}
          />
        </DisplayIf>

        <DisplayIf<TriggerSchema>
          name="triggerableType"
          condition={(value) => value === TriggerableTypes.Option}
        >
          <Controller
            name="triggerable"
            control={form.control}
            shouldUnregister
            render={({ field }) => {
              return (
                <BaseField
                  name="triggerable"
                  label="Opção de ativação"
                  flexGrow={0}
                >
                  <OptionSelect
                    selectionMode="single"
                    value={field.value?.map((e) => e?.id) as any}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys);
                    }}
                  />
                </BaseField>
              );
            }}
          />
        </DisplayIf>
        <DisplayIf<TriggerSchema>
          name="triggerableType"
          condition={(value) => value === TriggerableTypes.Step}
        >
          <Controller
            name="triggerable"
            control={form.control}
            shouldUnregister
            render={({ field }) => {
              return (
                <BaseField
                  name="triggerable"
                  label="Etapa de ativação"
                  flexGrow={0}
                >
                  <AutomationStepsSelect
                    selectionMode="single"
                    automationFlowId={automationFlowId}
                    value={field.value?.map((e) => e?.id) as any}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys);
                    }}
                  />
                </BaseField>
              );
            }}
          />
        </DisplayIf>
      </chakra.div>

      <DisplayIf<TriggerSchema>
        name="triggerableType"
        condition={(value) => value === TriggerableTypes.Option}
      >
        <DisplayIf<TriggerSchema>
          name="triggerable"
          condition={(value) => !!value}
        >
          <chakra.div display="flex" flexDir="column" gap="4">
            <Field
              type="radio"
              name="delayType"
              label="Tipo de ativação"
              size="lg"
              defaultValue="immediate"
              options={[
                { value: 'immediate', label: 'Imediata' },
                {
                  value: 'delay',
                  label: 'Adiar',
                },
              ]}
            />
            <DisplayIf<TriggerSchema>
              name="delayType"
              condition={(value) => value === 'delay'}
            >
              <Controller
                name="dateQuestion"
                control={form.control}
                shouldUnregister
                render={({ field }) => (
                  <BaseField
                    name="dateQuestion"
                    label="Herdar data de início da ativação a partir da pergunta:"
                    flexGrow={0}
                  >
                    <QuestionSelect
                      selectionMode="single"
                      questionTypes={[
                        QuestionTypes.Date,
                        QuestionTypes.DateSla,
                      ]}
                      parentOptionId={
                        triggerableType === TriggerableTypes.Option &&
                        !!triggerable.length
                          ? triggerable[0].id
                          : undefined
                      }
                      value={field.value?.map((e) => e?.id) as any}
                      initialOptions={(field.value || []) as any}
                      onChange={(keys) => {
                        field.onChange(keys?.length ? keys : undefined);
                      }}
                    />
                  </BaseField>
                )}
              />
            </DisplayIf>
          </chakra.div>
        </DisplayIf>
      </DisplayIf>

      <DisplayIf<TriggerSchema>
        name="triggerableType"
        condition={(value) => !!value}
      >
        <Field<TriggerSchema>
          name="label"
          label="Etiqueta da ativação"
          type="text"
          placeholder="Digite..."
          help="Use a etiqueta para identificar esta ativação"
        />
      </DisplayIf>
    </FormLayout>
  );
}

export type TriggerFormProps = {
  onSubmit?: (value: TriggerSchema) => void;
  automationFlowId: string;
  intitialValues?: TriggerSchema;
};
export function TriggerForm({
  intitialValues,
  onSubmit,
  automationFlowId,
}: TriggerFormProps) {
  return (
    <HookForm<TriggerSchema>
      autoComplete="off"
      overflowY="auto"
      overflowX="clip"
      position="relative"
      p="4"
      display="grid"
      flexGrow={0}
      w="full"
      id="trigger-form"
      resolver={yupResolver(triggerSchema)}
      schema={triggerSchema}
      defaultValues={intitialValues}
      onSubmit={(values) => onSubmit?.(values as TriggerSchema)}
    >
      <Fields automationFlowId={automationFlowId} />
    </HookForm>
  );
}

export function useTriggerForm({
  onSubmit,
  automationFlowId,
}: TriggerFormProps) {
  const modals = useModals();

  const _onSubmit = React.useCallback(
    (value: TriggerSchema) => {
      onSubmit?.(value);
      modals.close('trigger-form');
    },
    [modals, onSubmit],
  );

  return (intitialValues?: TriggerSchema) => {
    modals.open({
      id: 'trigger-form',
      title: 'Configurar Ativação',
      scope: 'alert',
      body: (
        <TriggerForm
          automationFlowId={automationFlowId}
          onSubmit={_onSubmit}
          intitialValues={intitialValues}
        />
      ),
      size: 'lg',
      closeOnEsc: false,
      closeOnOverlayClick: false,
      footer: (
        <Button
          type="submit"
          form="trigger-form"
          w="full"
          size="md"
          colorScheme="brand"
        >
          Salvar
        </Button>
      ),
    });
  };
}
