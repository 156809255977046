import { FragmentType, gql } from '@/gql';
import { getApolloInstance } from '@/modules/graphql';
import { useQuery } from '@apollo/client';

export const AutomationStep_StepFragment = gql(`#graphql
  fragment AutomationStep_StepFragment on Step {
    id
    position
    name
    stepType
    autoFinish
    triggers {
      id
      name
      triggerableId
      triggerableName
      triggerableType
      delayType
      dateQuestionId
      dateQuestion {
        id
        name
      }
    }
    authorId
    actionTask {
      id
      memberSetId
      lastMemberTask
      memberTask
      formularyId
      memberSet {
        id
        fullName
      }
      formulary {
        id
        name
      }
      localSet {
        id
        name
      }
      localTicketProperty {
        id
        name
      }
      memberQuestion {
        id
        name
      }
      localTask
      localSetId
      localTicketPropertyId
    }
    automationFlowId
    actionWebhooks {
      id
      name
      endpoint
    }
    automationStepActions {
      id
      actionType
    }
    actionEmailChecklist {
      sendChecklistOpenFlow
      questionId
      question {
        id
        name
      }
      name
      id
    }
    actionEmailUser {
      id
      name
      sendChecklistOpenFlow
      users {
        id
        fullName
        email
      }
      value
    }
    actionPropertyUpdate {
      id
      name
      automationPropertyId
      automationProperty {
        id
        name
      }
      question {
        id
        name
      }
    }
  }
`);

export type AutomationStepFragment = FragmentType<
  typeof AutomationStep_StepFragment
>;

const queryAutomationStep = gql(`#graphql
  query AutomationStep($automationStepId: String!) {
    automationStep(id: $automationStepId) {
      ...AutomationStep_StepFragment
    }
  }
`);

export const queryAutomationStepsConnection = gql(`#graphql
  query AutomationStepsConnection($after: String, $before: String, $first: Int, $last: Int, $automationFlowId: ID, $search: String) {
    automationStepsConnection(after: $after, before: $before, first: $first, last: $last, automationFlowId: $automationFlowId, search: $search) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`);

export const fetchAutomationSteps = async ({
  after,
  first,
  search,
  automationFlowId,
}: {
  after?: string;
  search?: string;
  first?: number;
  automationFlowId: string;
}) => {
  const apollo = getApolloInstance();
  const { data } = await apollo.query({
    query: queryAutomationStepsConnection,
    variables: {
      after,
      search,
      first,
      automationFlowId,
    },
    fetchPolicy: 'network-only',
  });

  const items =
    data?.automationStepsConnection.edges.map((edge) => edge.node!) || [];

  return {
    items,
    cursor: undefined,
  };
};

export function useFetchAutomationStep(id?: string) {
  const { data, loading, error } = useQuery(queryAutomationStep, {
    variables: { automationStepId: id || '' },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  return { data, loading, error };
}
