import { IconBaseProps } from 'react-icons';
import { GrLanguage } from 'react-icons/gr';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  padding: 10px;
  border: none;
  margin: 10px;
  cursor: pointer;
  :hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

export const LanguageIcon = styled(GrLanguage)<IconBaseProps>`
  color: ${(props) => props.theme.colors.darkText};
  margin-left: 3px;
`;
