import React, { Suspense } from 'react';
import {
  Switch,
  RouteProps,
  useRouteMatch,
  Route,
  Redirect,
} from 'react-router-dom';

import Layout from '@/screens/utils/AppLayout';

const Dashboard = React.lazy(() => import('@/screens/Dashboard'));

const DashboardRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Suspense fallback={null}>
        <Switch>
          <Route path={`${path}`} component={Dashboard} exact />
          <Route path={`${path}/:dashStartId`} component={Dashboard} />
          <Redirect to={`${path}`} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default DashboardRoutes;
