import { useModals } from '@/hooks/ModalManager';
import {
  Button,
  Flex,
  HStack,
  List,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { FileTransferProviders } from '../hooks/providers';
import { ExportItemProvider } from '../hooks/useExportItem';
import { useExports } from '../hooks/useExports';
import { ImportItemProvider } from '../hooks/useImportItem';
import { useImports } from '../hooks/useImports';
import { ExportItem } from './ExportItem';
import { ImportItem } from './ImportItem';

function ExportTabs() {
  const { exports, onFetchMore, pageInfo, loading } = useExports();
  return (
    <Flex w="full" overflow="hidden">
      <List w="full" spacing="4">
        {exports.map((exportItem) => (
          <ExportItemProvider exportItem={exportItem} key={exportItem.id}>
            <ExportItem />
          </ExportItemProvider>
        ))}
        <VStack w="full" gap="4" justifyContent="center">
          {!!loading && <Spinner />}
          {pageInfo?.hasNextPage && (
            <Button onClick={() => onFetchMore()} size="lg">
              Carregar mais
            </Button>
          )}
        </VStack>
      </List>
    </Flex>
  );
}

function ImportsTab() {
  const { imports, pageInfo, loading, onFetchMore } = useImports();
  return (
    <Flex w="full" overflow="hidden">
      <List w="full" spacing="4">
        {imports.map((importItem) => (
          <ImportItemProvider importItem={importItem} key={importItem.id}>
            <ImportItem />
          </ImportItemProvider>
        ))}
        <VStack w="full" gap="4" justifyContent="center">
          {!!loading && <Spinner />}
          {pageInfo?.hasNextPage && (
            <Button onClick={() => onFetchMore()} size="lg">
              Carregar mais
            </Button>
          )}
        </VStack>
      </List>
    </Flex>
  );
}

export function FileTransferDrawer() {
  return (
    <FileTransferProviders>
      <Tabs isLazy overflow="hidden" flex="1" variant="enclosed" size="lg">
        <TabList>
          <Tab>Exportações</Tab>
          <Tab>Importações</Tab>
        </TabList>
        <TabPanels overflow="auto" pb="10" h="99%">
          <TabPanel>
            <ExportTabs />
          </TabPanel>
          <TabPanel>
            <ImportsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FileTransferProviders>
  );
}

export function useFileTransferDrawer() {
  const modals = useModals();

  const open = () => {
    modals.drawer({
      title: 'Transferências de arquivo',
      body: <FileTransferDrawer />,
      footer: (
        <Flex>
          <Button>Fechar</Button>
        </Flex>
      ),
      size: 'lg',
    });
  };

  return open;
}
