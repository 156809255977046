/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from '@/gql';
import { getApolloInstance } from '@/modules/graphql';
import { useLazyQuery } from '@apollo/client';

const queryOptions = gql(`#graphql
  query Options(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $optionsId: ID
    $questionId: ID
    $orderBy: OrderNames
    $search: String
  ) {
    options(
      after: $after
      before: $before
      first: $first
      last: $last
      id: $optionsId
      questionId: $questionId
      orderBy: $orderBy
      search: $search
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          question {
            id
            name
            formulary {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export const fetchOptions = async (params: {
  after?: string;
  search?: string;
  first?: number;
}) => {
  const apollo = getApolloInstance();
  const { data } = await apollo.query({
    query: queryOptions,
    variables: { first: 20, ...params },
    fetchPolicy: 'network-only',
  });

  const items = data.options.edges.map((edge) => edge.node!);

  return {
    items,
    cursor: data?.options?.pageInfo?.endCursor || undefined,
  };
};

export const useFetchOptions = () => {
  const [fetch, { data, loading }] = useLazyQuery(queryOptions);
  let parsedOptions;

  if (data && data.options) {
    const { options } = data;
    parsedOptions = options?.edges.map((edge) => ({
      label: edge.node?.name,
      value: edge.node?.id,
    }));
  }

  return { data: parsedOptions || null, loading, fetch };
};
