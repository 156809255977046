import { useModals } from '@/hooks/ModalManager';
import ItemEditScreen from '@/screens/ItemEdit/ItemEdit.screen';
import { EditIcon } from '@chakra-ui/icons';
import {
  Badge,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  SkeletonText,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

import { useEventItemShow } from '../../hooks/useEventItemShow';
import { useInteractions } from '../../hooks/useInteractions';
import { useScore } from '../../hooks/useScore';
import { useSelectedForm } from '../../hooks/useSelectedForm';

export const FormDetails = () => {
  const modals = useModals();
  const form = useSelectedForm();
  const { selectedItemId, selectedFormId } = useInteractions();
  const { item, isLoading, refetch } = useEventItemShow();
  const { score, totalScore, scoreColor } = useScore(item as any);

  const [refreshPage, setRefreshPage] = React.useState(false);

  const handleRefresh = () => {
    setRefreshPage(true);
  };

  // Use useEffect to trigger refresh when refreshPage state changes
  React.useEffect(() => {
    if (refreshPage) {
      setTimeout(() => {
        window.location.reload();
        setRefreshPage(false);
      }, 1000);
    }
  }, [refreshPage]);

  const itemWithProps = React.useMemo(() => {
    const curr = form?.items.find((e) => e.id === selectedItemId);
    if (curr) {
      return curr;
    }
    return null;
  }, [form?.items, selectedItemId]);

  const onEdit = React.useCallback(() => {
    if (!selectedItemId || !selectedFormId) return;
    modals.open({
      size: 'full',
      closeOnEsc: false,
      id: 'item-edit',
      body: (
        <ItemEditScreen
          onSubmit={async () => {
            modals.close('item-edit');
            // refetch();
            // eslint-disable-next-line no-restricted-globals
            handleRefresh(); // FIXME - gambiarra
          }}
          formularyId={selectedFormId}
          itemId={selectedItemId}
        />
      ),
    });
  }, [modals, selectedFormId, selectedItemId]);

  return (
    <Flex
      bg="white"
      p="4"
      mx="4"
      mb="4"
      rounded="xl"
      flexDir="column"
      borderWidth="thin"
    >
      <HStack
        hidden={!form?.hasCollections && !form?.recurrent}
        w="full"
        mb="1.5"
      >
        {form?.hasCollections && (
          <Tag colorScheme="purple" size="sm">
            Coleções
          </Tag>
        )}
        {form?.recurrent && (
          <Tag colorScheme="orange" size="sm">
            Recorrente
          </Tag>
        )}
      </HStack>
      <Flex
        w="full"
        flexDir="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Skeleton isLoaded={!isLoading && !!item}>
          <Heading>{form?.formName}</Heading>
        </Skeleton>
        <ButtonGroup mt="-1.5">
          <IconButton
            onClick={onEdit}
            icon={<EditIcon fontSize="18px" />}
            size="md"
            aria-label="edit"
            variant="elevated"
          />
        </ButtonGroup>
      </Flex>

      <SkeletonText
        noOfLines={1}
        mt={isLoading ? 2 : 0}
        isLoaded={!isLoading && !!item}
      >
        <VStack w="full" alignItems="flex-start">
          <HStack spacing="2">
            <Text fontWeight="bold">Respondido em:</Text>
            <Text>
              {itemWithProps?.finishedAt
                ? moment(itemWithProps?.finishedAt).format('LLLL')
                : ''}
            </Text>

            <Badge
              hidden={!totalScore}
              variant="outline"
              colorScheme={scoreColor}
            >
              <Text>{`Pontuação no item: ${score}%`}</Text>
            </Badge>
          </HStack>
          <Text translate="no">
            Feedbacks: {itemWithProps?.countFeedbacks} /{' '}
            {itemWithProps?.countAnswers}{' '}
          </Text>
          {!!itemWithProps?.collectionItemName && (
            <Text>{`Item: ${itemWithProps?.collectionItemName}`}</Text>
          )}
        </VStack>
      </SkeletonText>
    </Flex>
  );
};
