/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

interface ContentProp {
  isOpen: boolean;
  isLeftSided: boolean;
  zIndex?: number;
  width?: string;
}

export const Container = styled.div<ContentProp>`
  flex: 1;
  position: fixed;
  top: 0;
  ${(props) =>
    props.isLeftSided
      ? css`
          left: 0px;
        `
      : css`
          right: 0;
        `};
  height: 100vh;
  width: 100vw;
  z-index: 20; // ${(props) => (props.zIndex ? 20 + props.zIndex : 20)};
  display: flex;
  justify-content: flex-end;

  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: transform 300ms ease-in-out, visibility 300ms ease-in-out;
  ${({ isOpen }) =>
    isOpen
      ? 'animation: fadeIn 200ms linear;'
      : 'animation: fadeOut 200ms linear;'};

  @keyframes fadeIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  @keyframes fadeOut {
    from {
      background-color: rgba(0, 0, 0, 0.3);
    }
    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  animation-fill-mode: forwards;
`;

export const BackView = styled.div`
  @media (min-width: 768px) {
    flex: 2;
  }
`;

export const Content = styled.div<ContentProp>`
  /* flex: 1; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 10px 48px -2px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
  min-width: 300px;
  max-width: 500px;
  ${(props) => {
    if (props.width) {
      return `width: ${props.width};`;
    }
  }}

  transform: ${({ isOpen, isLeftSided }) => {
    const displacement = isLeftSided
      ? 'translateX(-100%)'
      : 'translateX(+100%)';
    return isOpen ? 'translateX(0%)' : displacement;
  }};
  transition: transform 200ms ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Header = styled.div`
  padding: 20px 25px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTile = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h3`
  margin-left: 10px;
  font-weight: 400;
`;

export const SubText = styled.h3`
  font-size: 0.9em;
  color: ${(props) => props.theme.colors.lightText};
  font-weight: 400;
`;
