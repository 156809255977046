import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 55px;
  display: flex;
  background-color: #f8f8f8;
  padding: 6px 12px;
  border-radius: 5px;

  p {
    color: ${(props) => props.theme.colors.darkerText};
    font-size: 12px;
  }
`;
