import { getContrastColor } from '@/utils/colors/getContrastColor';
import { stringToColor } from '@/utils/colors/stringToColor';
import { getInitials } from '@/utils/Text/getInitials';
import React from 'react';
import { FiCalendar, FiDownloadCloud, FiUploadCloud, FiMessageCircle } from 'react-icons/fi';
import { NotificationIconProps } from './interfaces';

import { Icon, AvatarInitials } from './styles';

const NotificationItemIcon: React.FC<NotificationIconProps> = ({
  notification,
}) => {
  let actionColor = '';
  switch (notification.action) {
    case 'success': {
      actionColor = '#0DB2D6';
      break;
    }
    case 'failure': {
      actionColor = '#EB5757';
      break;
    }
    default: {
      actionColor = '#0DB2D6';
      break;
    }
  }
  switch (notification.type) {
    case 'note':
      return (
        <Icon backgroundColor={actionColor}>
          <FiMessageCircle />
        </Icon>
      );
    case 'import_finished':
      return (
        <Icon backgroundColor={actionColor}>
          <FiUploadCloud />
        </Icon>
      );
    case 'export_finished':
      return (
        <Icon backgroundColor={actionColor}>
          <FiDownloadCloud />
        </Icon>
      );
    case 'event_started':
    case 'event_finished':
      return (
        <Icon backgroundColor={actionColor}>
          <FiCalendar />
        </Icon>
      );
    case 'topic_comment':
    case 'topic_like':
    case 'topic_dislike':
      const { name } = notification.body.user;
      const avatarInitials = getInitials(name);
      const avatarColor = stringToColor({
        str: name,
        saturation: 60,
        luminance: 83,
        mode: 'hex',
      });
      const textContrastColor = getContrastColor(avatarColor);
      return (
        <Icon backgroundColor={avatarColor}>
          <AvatarInitials color={textContrastColor}>
            {avatarInitials}
          </AvatarInitials>
        </Icon>
      );
    default:
      return (
        <Icon backgroundColor="#0DB2D6">
          <FiDownloadCloud />
        </Icon>
      );
  }
};

export default NotificationItemIcon;
