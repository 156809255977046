import React from 'react';

import i18n from '@/i18n';

import { NotificationType } from '@/hooks/Notifications/interfaces';
import Tag from './components/Tag';

const renderTags = (
  type: NotificationType | 'topic',
  specific: boolean = false,
  closable: boolean = false,
): JSX.Element[] => {
  const text = i18n.t(`notifications.tags.${type}`);
  const feedback = i18n.t('notifications.tags.feedback');

  switch (type) {
    case 'note':
    case 'event_started':
    case 'event_finished':
      return [
        <Tag
          key={type}
          closable={closable}
          color="#85868"
          backgroundColor="#E1E4E8"
        >
          {text}
        </Tag>,
      ];
    case 'topic':
      return [
        <Tag
          key="feedback"
          closable={closable}
          color="#8762F2"
          backgroundColor="#E1D6F8"
        >
          {feedback}
        </Tag>,
      ];
    case 'topic_like':
      if (!specific) {
        return [
          <Tag key="type" color="#8762F2" backgroundColor="#E1D6F8">
            {feedback}
          </Tag>,
          <Tag key={type} color="#55BE65" backgroundColor="#D0E7E1">
            {text}
          </Tag>,
        ];
      }
      return [
        <Tag
          key={type}
          closable={closable}
          color="#55BE65"
          backgroundColor="#D0E7E1"
        >
          {text}
        </Tag>,
      ];
    case 'topic_dislike':
      if (!specific) {
        return [
          <Tag key="type" color="#8762F2" backgroundColor="#E1D6F8">
            {feedback}
          </Tag>,
          <Tag key={type} color="#F07878" backgroundColor="#FCDADA">
            {text}
          </Tag>,
        ];
      }
      return [
        <Tag
          key={type}
          closable={closable}
          color="#F07878"
          backgroundColor="#FCDADA"
        >
          {text}
        </Tag>,
      ];
    case 'topic_comment':
      return [
        <Tag
          key={type}
          closable={closable}
          color="#8762F2"
          backgroundColor="#E1D6F8"
        >
          {text}
        </Tag>,
      ];
    case 'export_finished':
      return [
        <Tag
          key={type}
          closable={closable}
          color="#85868"
          backgroundColor="#E1E4E8"
        >
          {text}
        </Tag>,
      ];
    case 'import_finished':
      return [
        <Tag
          key={type}
          closable={closable}
          color="#85868"
          backgroundColor="#E1E4E8"
        >
          {text}
        </Tag>,
      ];
    default:
      return [];
  }
};

export default renderTags;
