/* eslint-disable react/require-default-props */
import {
  Box,
  Button,
  HStack,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BiCopy } from 'react-icons/bi';
import { FaQrcode } from 'react-icons/fa';
import QRCode from 'react-qr-code';

import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { downloadQrCode } from '../utils';

export function QrCodeGenerator(props: {
  isLoading?: boolean;
  templateId?: string;
}) {
  const copyToClipboard = useCopyToClipboard();
  const { isLoading, templateId } = props;

  const url = `https://station.wecheckstation.com.br/external/deep_link?callbackUrl=wecheckapp://task_templates/${templateId}`;

  const handleDownload = React.useCallback(() => {
    downloadQrCode(templateId!);
  }, [templateId]);
  return (
    <Stack
      borderBottomWidth="thin"
      w="full"
      spacing="4"
      pb="10"
      textAlign="center"
      alignItems="center"
      position="relative"
    >
      <Spinner
        mx="auto"
        my="auto"
        position="absolute"
        size="xl"
        top={-10}
        bottom={0}
        left={0}
        right={0}
        hidden={!isLoading}
      />
      {templateId ? (
        <VStack w="full" gap="4">
          <QRCode id="qrcode" size={200} value={url} />
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            borderWidth="thin"
            borderRadius="md"
            p="2"
            bg="gray.50"
            textAlign="start"
          >
            <Link
              fontSize="sm"
              href={url}
              color="blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              {url}
            </Link>
            <IconButton
              onClick={() => {
                copyToClipboard(url);
              }}
              variant="solid"
              size="md"
              icon={<BiCopy />}
              aria-label="Copiar"
            />
          </HStack>
        </VStack>
      ) : (
        <FaQrcode color="var(--chakra-colors-gray-300)" size={200} />
      )}
      <Text
        fontSize="lg"
        fontWeight="bold"
        color="gray.500"
        hidden={!!templateId}
      >
        Preencha os dados abaixo, e salve-os para gerar um QrCode
      </Text>

      <Stack direction="row" px="10" spacing="10" hidden={!templateId}>
        <Button onClick={handleDownload} size="md">
          Salvar QrCode
        </Button>
      </Stack>
    </Stack>
  );
}
