import Loading from '@/components_v2/Loading';
import config from '@/Constants';
import { Collection } from '@/modules/core/domain/interfaces/ICollection';
import { QueryResponse } from '@/modules/queryfilter/domain/interfaces';
import {
  Button,
  HStack,
  List,
  ListItem,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-query';

import DebouncedInput from '../../DebouncedInput';
import { CollectionsListProps } from './CollectionsList.types';

const CollectionsInputList: React.FC<CollectionsListProps> = ({
  onSelect,
  selected,
  queryKey = config.COLLECTIONS_URL,
}) => {
  const [page, setPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined,
  );

  const { data, isLoading } = useQuery<QueryResponse<Collection>>(
    `${queryKey}?size=20&page=${page}${
      searchQuery ? `&search=${searchQuery}` : ''
    }`,
  );

  const [items, setItems] = React.useState<Collection[]>([]);

  React.useEffect(() => {
    if (data) {
      setItems(data.items);
      setHasNextPage(data.hasNextPage);
    }
  }, [data]);

  const handleSelect = React.useCallback(
    (collection: Collection) => {
      if (selected && collection.id === selected.id) {
        return;
      }
      onSelect(collection);
    },
    [onSelect, selected],
  );

  const onSearch = React.useCallback((value: string) => {
    if (value.length > 0) {
      setSearchQuery(value);
    } else {
      setSearchQuery(undefined);
    }
  }, []);

  return (
    <VStack
      w="50%"
      borderRight="1px"
      borderRightStyle="solid"
      pr={2}
      borderRightColor="gray.200"
    >
      <Text>Coleções</Text>
      <DebouncedInput onChange={onSearch} />
      <List h="50vh" overflowY="auto" spacing={1} w="full">
        {isLoading ? (
          <Loading />
        ) : (
          items.map((item) => (
            <ListItem
              onClick={() => handleSelect(item)}
              p={2}
              borderRadius="md"
              fontWeight="semibold"
              bg={selected && selected.id === item.id ? 'blue.100' : 'none'}
              _hover={{ bg: 'gray.100' }}
              key={item.id}
              cursor="pointer"
            >
              {item.name}
            </ListItem>
          ))
        )}
      </List>
      <Spacer />
      <HStack
        w="full"
        borderTop="1px"
        borderStyle="solid"
        borderColor="gray.100"
        minHeight="fit-content"
        justifyContent="center"
        pt={2}
        spacing={4}
      >
        {page > 1 ? (
          <Button onClick={() => setPage((prev) => prev - 1)} size="sm">
            {page - 1}
          </Button>
        ) : (
          <Button
            size="sm"
            isDisabled
            cursor="default"
            bg="none"
            _hover={{ bg: 'none', cursor: 'default' }}
          >
            {` `}
          </Button>
        )}
        <Text>{page}</Text>
        {hasNextPage ? (
          <Button size="sm" onClick={() => setPage((prev) => prev + 1)}>
            {page + 1}
          </Button>
        ) : (
          <Button
            size="sm"
            isDisabled
            cursor="default"
            bg="none"
            _hover={{ bg: 'none', cursor: 'default' }}
          >
            {` `}
          </Button>
        )}
      </HStack>
    </VStack>
  );
};

export default CollectionsInputList;
