import { FieldStateMap } from '@/modules/WebForm/interfaces';

import { IFormularyState } from '../types';

export function parseFormularyToFields(
  formulary?: IFormularyState,
): FieldStateMap {
  if (!formulary?.sections) return {};
  const { sections } = formulary;

  return sections.reduce((prev, curr) => {
    const next = curr.questions
      .filter((el) => el.enabled)
      .reduce((prevQuestions, currentQuestion) => {
        return {
          [currentQuestion.id]: {
            id: currentQuestion.id,
            name: currentQuestion.name,
            required: currentQuestion.required,
            parentComparisonType: 'option_id', // currently only implementation
            parentValueMatch: currentQuestion.parentOptionId,
            parentFieldId: currentQuestion.parentQuestionId,
            conditional: currentQuestion.conditional,
            options: currentQuestion.options,
          },
          ...prevQuestions,
        } as FieldStateMap;
      }, {} as FieldStateMap);

    return {
      ...next,
      ...prev,
    };
  }, {} as FieldStateMap);
}
