import Loading from '@/components/Loading';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getDifferenceInDays } from '@/utils/Date/parseDayDate';
import NotificationItem from '../NotificationItem';
import { NotificationsListRenderProps } from './interfaces';

import { Container, List, EmptyItem, TagsList } from './styles';
import NotificationsFilters from './components/NotificationsFilters';
import SectionDivider from './components/SectionDivider';

const NotificationsList: React.FC<NotificationsListRenderProps> = ({
  isValidating,
  notifications,
}) => {
  const { t } = useTranslation();

  const renderItems = useCallback(() => {
    let olderReached = false;
    let todayReached = false;
    let yesterdayReached = false;
    return notifications.map((notification) => {
      const component = (
        <NotificationItem key={notification.id} notification={notification} />
      );
      const diff = getDifferenceInDays(
        notification.sentTime,
        new Date().getTime(),
      );
      if (diff < 1 && !todayReached) {
        todayReached = true;
        return (
          <>
            <SectionDivider key="today">{t('time.today')}</SectionDivider>
            {component}
          </>
        );
      }
      if (diff >= 1 && diff < 2 && !yesterdayReached) {
        yesterdayReached = true;
        return (
          <>
            <SectionDivider key="yesterday">
              {t('time.yesterday')}
            </SectionDivider>
            {component}
          </>
        );
      }
      if (diff >= 2 && !olderReached) {
        olderReached = true;
        return (
          <>
            <SectionDivider key="older">{t('time.older')}</SectionDivider>
            {component}
          </>
        );
      }
      return component;
    });
  }, [notifications, t]);

  return (
    <Container>
      {notifications.length === 0 && isValidating ? (
        <Loading />
      ) : (
        <>
          <TagsList>
            <div style={{ display: 'flex', padding: '4px 0 0px' }}>
              <NotificationsFilters />
            </div>
          </TagsList>
          <List>
            {notifications.length > 0 ? (
              renderItems()
            ) : (
              <EmptyItem>{t('notifications.no_notifications')}</EmptyItem>
            )}
          </List>
        </>
      )}
    </Container>
  );
};

export default NotificationsList;
