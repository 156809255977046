/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiX } from 'react-icons/fi';

import { useActionSheet } from '@hooks/actionSheet';
import {
  ActionSheetComponentProps,
  ActionSheetProps,
} from '@hooks/interfaces/actionSheet';

import {
  Container,
  BackView,
  Content,
  Header,
  HeaderTile,
  Title,
  SubText,
  HeaderContent,
} from './styles';

const emptyActionSheet: ActionSheetProps = {
  actionSheetKey: '',
  closeWindow: () => {},
  isOpen: false,
  title: '',
};

function findActionSheetItem(
  stack: ActionSheetProps[],
  side: 'right' | 'left',
): ActionSheetProps | undefined {
  for (let i = stack.length; i >= 0; i--) {
    if (
      stack[i] &&
      ((!stack[i].side && side === 'right') || stack[i].side === side)
    )
      return stack[i];
  }
  return undefined;
}

const ActionSheet: React.FC<ActionSheetComponentProps> = ({
  children,
  closeWindow,
  isOpen,
  subTitle,
  title,
  actionSheetKey,
  zIndex,
  side,
  headerActionComponent,
}) => {
  const { state } = useActionSheet();
  const [current, setCurrent] = useState<ActionSheetProps>(emptyActionSheet);
  useEffect(() => {
    const { stack } = state;
    if (stack.length > 0) {
      const actionSheetItem = findActionSheetItem(stack, side);
      setCurrent(actionSheetItem || emptyActionSheet);
    } else {
      setCurrent(emptyActionSheet);
    }
  }, [state, side]);

  const { closeActionSheet } = useActionSheet();
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    if (closeWindow) {
      closeWindow();
    } else if (isOpen === undefined) {
      current.closeWindow();
      closeActionSheet(current.actionSheetKey);
    }
  }, [closeActionSheet, current, closeWindow, isOpen]);
  return (
    <Container
      zIndex={zIndex}
      isOpen={isOpen || current.isOpen}
      isLeftSided={side === 'left'}
    >
      {side === 'left' ? (
        <>
          <Content
            isOpen={isOpen || current.isOpen}
            isLeftSided={side === 'left'}
            width={current.styles?.width}
          >
            <Header>
              {headerActionComponent || current.headerActionComponent}
              <HeaderContent>
                <HeaderTile>
                  <FiX
                    style={{ cursor: 'pointer' }}
                    size={20}
                    onClick={onClose}
                  />
                  <Title>{t(title || current.title)}</Title>
                </HeaderTile>
                <SubText>{t(subTitle || current.subTitle || '')}</SubText>
              </HeaderContent>
            </Header>
            {children || current.children}
          </Content>
          <BackView onClick={onClose} />
        </>
      ) : (
        <>
          <BackView onClick={onClose} />
          <Content
            width={current.styles?.width}
            isOpen={isOpen || current.isOpen}
            isLeftSided={false}
          >
            <Header>
              <HeaderContent>
                <HeaderTile>
                  <FiArrowLeft
                    style={{ cursor: 'pointer' }}
                    size={20}
                    onClick={onClose}
                  />
                  <Title>{t(title || current.title)}</Title>
                </HeaderTile>
                <SubText>{t(subTitle || current.subTitle || '')}</SubText>
              </HeaderContent>
              {headerActionComponent || current.headerActionComponent}
            </Header>
            {children || current.children}
          </Content>
        </>
      )}
    </Container>
  );
};

export default ActionSheet;
