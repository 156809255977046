/* eslint-disable react/react-in-jsx-scope */

import {
  TemplateTaskUrlFragmentFragment,
  TemplateUrlTypes,
} from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import { CopyIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  chakra,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Link,
  List,
  ListItem,
  Spinner,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import {
  getFragmentData,
  templateTaskUrlEmailFragment,
  templateTaskUrlFragment,
  useCreateTemplateTaskUrl,
  useTemplateTaskUrlsQuery,
  useToggleUrlActive,
} from '../hooks/useShareTemplateTask';
import { getShareableLink } from '../utils';
import { useShareViewModal } from './ShareViewModal';

const gridTemplateColumns = '0.9fr 0.5fr 0.6fr 0.7fr 0.2fr 0.4fr';

const urlTypes = {
  [TemplateUrlTypes.OnlyUse]: 'Uso único',
  [TemplateUrlTypes.Recurrency]: 'Recorrente',
};

function ShareItem({ item }: { item: TemplateTaskUrlFragmentFragment }) {
  const copyToClipboard = useCopyToClipboard();
  const view = useShareViewModal();

  const link = getShareableLink(item.id);

  const [isActive, setActive] = React.useState(!!item.active);

  const [update, payload] = useToggleUrlActive();

  const handleUpdate = React.useCallback(() => {
    const nextState = !isActive;

    setActive(nextState);

    update({
      id: item.id,
      active: nextState,
    });
  }, [isActive, item.id, update]);

  return (
    <ListItem borderBottomWidth="thin" py={3}>
      <Grid
        gridTemplateColumns={gridTemplateColumns}
        w="full"
        gap="1"
        color="blackAlpha.700"
        fontWeight="bold"
      >
        <GridItem alignItems="center" display="flex" pl="2">
          <Link href={link.href}>
            <Text noOfLines={2}>{link.href}</Text>
          </Link>
        </GridItem>
        <GridItem
          justifyContent="center"
          alignItems="center"
          display="flex"
          textAlign="center"
        >
          {!!item.urlType && <span>{urlTypes[item.urlType]}</span>}
        </GridItem>
        <GridItem alignItems="center" display="flex" pl="2">
          <Text>{moment(new Date(item.updatedAt)).format('LLL ')}</Text>
        </GridItem>
        <GridItem alignItems="center" display="flex" pl="2">
          <ul>
            {item.templateTaskUrlEmails
              ?.slice(0, 3)
              ?.map((email) => {
                const frag = getFragmentData(
                  templateTaskUrlEmailFragment,
                  email,
                );
                return frag.email;
              })
              .map((e) => (
                <li key={e}>
                  <Text fontSize="sm" noOfLines={1} wordBreak="break-all">
                    {e}
                  </Text>
                </li>
              ))}
          </ul>
        </GridItem>
        <GridItem alignItems="center" display="flex" justifyContent="center">
          <Switch
            disabled={payload.loading}
            isChecked={isActive}
            onChange={handleUpdate}
          />
        </GridItem>
        <GridItem
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
          gap={2}
        >
          <Tooltip label="Copiar link para área de transferência">
            <IconButton
              onClick={() => {
                copyToClipboard(link.href);
              }}
              icon={<CopyIcon />}
              aria-label="copy"
            />
          </Tooltip>
          <Button onClick={() => view(item.id)}>Editar</Button>
        </GridItem>
      </Grid>
    </ListItem>
  );
}

function ShareListHeader() {
  return (
    <Grid
      gridTemplateColumns={gridTemplateColumns}
      position="sticky"
      zIndex="docked"
      top={0}
      w="full"
      h="10"
      bg="white"
      gap="1"
      color="blackAlpha.700"
      fontWeight="bold"
      borderBottomWidth="thin"
    >
      <GridItem pl="2">Link</GridItem>
      <GridItem justifyContent="center" display="flex">
        <span>Tipo</span>
      </GridItem>
      <GridItem pl="2">Úlima edição</GridItem>
      <GridItem pl="2">Emails</GridItem>
      <GridItem display="flex" justifyContent="center">
        <span>Ativo</span>
      </GridItem>
      <GridItem pl="2" />
    </Grid>
  );
}

function ShareList({ templateTaskId }: { templateTaskId: string }) {
  const { data, loading } = useTemplateTaskUrlsQuery({ templateTaskId });

  if (loading && !data?.templateTaskUrls) {
    return (
      <Center minH="36" bg="gray.100" rounded="md" flex="1">
        <Spinner />
      </Center>
    );
  }

  if (!data?.templateTaskUrls?.length) {
    return (
      <Center minH="36" bg="gray.100" rounded="md" flex="1">
        <Text>Nenhum compartilhamento ainda</Text>
      </Center>
    );
  }

  return (
    <List maxH={350} overflowY="auto" spacing={0} w="100%">
      <ShareListHeader />
      {data?.templateTaskUrls.map((item) => (
        <ShareItem key={item.id} item={item} />
      ))}
    </List>
  );
}

export function ShareListModal({ templateTaskId }: { templateTaskId: string }) {
  const viewShareLink = useShareViewModal();
  const [mutate, { loading }] = useCreateTemplateTaskUrl();

  const handleCreate = React.useCallback(() => {
    mutate({
      variables: {
        input: {
          templateTaskId,
          urlType: TemplateUrlTypes.OnlyUse,
          active: true,
        },
      },
      onCompleted(data) {
        if (!data.createTemplateTaskUrl?.templateTaskUrl) return;
        const fragment = getFragmentData(
          templateTaskUrlFragment,
          data.createTemplateTaskUrl?.templateTaskUrl,
        );
        viewShareLink(fragment.id);
      },
    });
  }, [mutate, templateTaskId, viewShareLink]);
  return (
    <Flex flex={1} px={6} pb={4} flexDir="column" gap="4">
      <chakra.p fontSize="lg">
        Crie links de compartilhamento para este template de tarefa
      </chakra.p>
      <Flex gap={4} flexDir="column" flex="1">
        <ShareList templateTaskId={templateTaskId} />
        <Button
          disabled={loading}
          isLoading={loading}
          onClick={handleCreate}
          colorScheme="brand"
        >
          Novo link de compartilhamento
        </Button>
      </Flex>
    </Flex>
  );
}

export function useShareListModal() {
  const modals = useModals();

  return (props: { templateTaskId: string }) => {
    const id = 'share_list_modal';
    modals.open({
      id,
      title: 'Compartilhamento',
      closeOnEsc: false,
      closeOnOverlayClick: false,
      size: '6xl',
      body: <ShareListModal {...props} />,
      footer: (
        <Button onClick={() => modals.pop()} size="lg" variant="outline">
          Voltar
        </Button>
      ),
    });
  };
}
