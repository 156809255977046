import LanguageSwitcher from '@/components_v2/LanguageSwitcher';
import Tooltip from '@components/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, LanguageIcon } from './styles';

export default function ChangeLanguageBtn() {
  const { t } = useTranslation();

  return (
    <Tooltip description={t('buttons.change_language')} placement="right center">
      <Container>
        <LanguageSwitcher>
          <LanguageIcon size={18} />
        </LanguageSwitcher>
      </Container>
    </Tooltip>
  );
}
