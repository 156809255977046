/* eslint-disable react/react-in-jsx-scope */

import { DataTable } from '@/components_v2/DataTable';
import ExporterContextMenu from '@/components_v2/ExporterContextMenu';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { TRASH_ICON } from '@/screens/Tickets/utils/customIcons';
import { Button, Flex, HStack, Icon, Input, Text } from '@chakra-ui/react';
import { FiDownload } from 'react-icons/fi';

import { useTaskTabsNavigation } from '../Dashboard/hooks/useTaskTabsNavigation';
import { getExporter } from '../services/getExporter';
import {
  PublicTasksContext,
  usePublicTasks,
  usePublicTasksContext,
} from './hooks/usePublicTasks';
import { getTableColumns } from './hooks/utils';

function TableContent() {
  const {
    items,
    loading,
    paginate,
    pageInfo,
    rowSelection,
    setRowSelection,
  } = usePublicTasksContext();

  return (
    <Flex
      overflowY="auto"
      display="block"
      flex="1"
      rounded="md"
      borderWidth="thin"
    >
      <DataTable
        isLoading={loading}
        onLoadMore={() => paginate()}
        withSelect
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        tableProps={{
          variant: 'striped',
          size: 'lg',
          fontSize: 'lg',
          background: 'white',
          fontWeight: 'semibold',
        }}
        headerProps={{
          h: 14,
          bg: 'white',
        }}
        columns={getTableColumns() as any}
        data={items}
      />
      {!!pageInfo?.hasNextPage && (
        <Flex p="10" alignItems="center" justifyContent="center">
          <Button colorScheme="brand" size="lg" onClick={() => paginate()}>
            Carregar mais
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

function Header() {
  const { setSearch, rowSelection, onDeleteTasks } = usePublicTasksContext();
  const { checkPermission } = useUserPermissions();
  const { setTab } = useTaskTabsNavigation();
  return (
    <Flex
      ps="4"
      rounded="md"
      bg="gray.200"
      pe="1.5"
      py="1.5"
      overflowX="auto"
      gap="4"
      flexDir="row"
      justifyContent="space-between"
    >
      <HStack w="full" spacing={4}>
        <Text>Buscar Tarefas</Text>
        <Input
          variant="filled"
          borderWidth="thin"
          borderColor="gray.300"
          placeholder="Buscar"
          w="xl"
          onChange={(e) => setSearch(e.target.value)}
        />
      </HStack>
      <HStack>
        <Button
          size="md"
          onClick={() => {
            setTab('templates');
          }}
          colorScheme="brand"
        >
          Compartilhar template
        </Button>

        <ExporterContextMenu
          getExporter={getExporter}
          options={{ only_public_events: true }}
          button={
            <Button
              size="md"
              variant="solid"
              rightIcon={<Icon as={FiDownload} />}
            >
              Exportar Tarefas
            </Button>
          }
          screenName="tasks"
        />

        <ExporterContextMenu
          getExporter={getExporter}
          options={{ only_public_events: true }}
          button={
            <Button
              size="md"
              variant="solid"
              rightIcon={<Icon as={FiDownload} />}
            >
              Exportar respostas
            </Button>
          }
          screenName="task_answers"
        />

        {!!(rowSelection && Object.values(rowSelection).length) && (
          <Button
            disabled={!(checkPermission('admin') || checkPermission('rw'))}
            onClick={() => onDeleteTasks()}
            rightIcon={<TRASH_ICON />}
            size="md"
            colorScheme="red"
          >
            Excluir tarefas
          </Button>
        )}
      </HStack>
    </Flex>
  );
}

export function Content() {
  return (
    <Flex overflow="0" w="full" flexDir="column" gap="4">
      <Header />

      <TableContent />
    </Flex>
  );
}

export function PublicTasks() {
  const hook = usePublicTasks();
  return (
    <PublicTasksContext value={hook}>
      <Content />
    </PublicTasksContext>
  );
}
