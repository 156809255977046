import { notificationTypes } from '@/hooks/Notifications/interfaces';
import { useNotificationsList } from '@/hooks/Notifications/notificationsList';
import { arrayContainsAll } from '@/utils/arrayContainsAll';
import React, { useCallback } from 'react';
import renderTags from '../../../common/renderTags';
import { TagContainer } from './styles';

const NotificationsFilters: React.FC = () => {
  const { addOrRemoveFilters, filters } = useNotificationsList();

  const renderFilters = useCallback(() => {
    const selectedArr = filters.map((type) => {
      return (
        <TagContainer key={type} onClick={() => addOrRemoveFilters([type])}>
          {renderTags(type, true, true)}
        </TagContainer>
      );
    });
    const unselectedArr = notificationTypes
      .filter((type) => !filters.includes(type))
      .map((type) => {
        return (
          <TagContainer key={type} onClick={() => addOrRemoveFilters([type])}>
            {renderTags(type, true)}
          </TagContainer>
        );
      });

    return [...selectedArr, ...unselectedArr];
  }, [addOrRemoveFilters, filters]);

  return <>{renderFilters()}</>;
};

export default NotificationsFilters;
