/* eslint-disable no-case-declarations */
import config from '@/Constants';
import { useFilter } from '@/hooks/Filters/filter';
import { useBatchPDFExporter } from '@/hooks/useBatchPDFExporter';
import { QueryResponse } from '@/modules/queryfilter/domain/interfaces';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Task } from '@/screens/registrations/Tasks/interfaces';
import debounce from '@/utils/debounce';
import { RowSelectionState } from '@tanstack/react-table';
import React, { createContext, useContext } from 'react';
import useSWR from 'swr';

import { TaskResponsible } from '../../interfaces';
import { TasksContextData } from './tasks.types';

const TasksContext = createContext<TasksContextData>({} as TasksContextData);

export const TasksProvider: React.FC = ({ children }) => {
  const [exportBatch] = useBatchPDFExporter();
  const [page, setPage] = React.useState(1);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>();
  const { getQueryString } = useFilter();
  const [totalItems, setTotalItems] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(8);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [queryString, setQueryString] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined,
  );
  const [responsibleFilter, setResponsibleFilter] = React.useState<
    TaskResponsible | undefined
  >(undefined);
  const { data, mutate, isValidating } = useSWR(
    `${config.TASKS_URL}?page=${page}&screen=audit&size=${itemsPerPage}${queryString}${
      responsibleFilter === 'all_tasks' ? '' : '&only_my_tasks=true'
    }`,
    async (url) => {
      const response = await AxiosHelper.get<QueryResponse<Task>>({ url });
      setHasNextPage(response.body.hasNextPage);
      setTotalItems(response.body.totalItems);
      return response.body.items;
    },
  );
  const [debouncedQuery] = React.useState(() =>
    debounce(async (func: () => void) => {
      func();
    }, 250),
  );

  const changeItemsPerPage = React.useCallback((items: number) => {
    setItemsPerPage(items);
    setPage(1);
    setRowSelection(undefined);
  }, []);

  React.useEffect(() => {
    debouncedQuery(() => {
      setPage(1);
      setQueryString(`${getQueryString()}${searchQuery || ''}`);
      setRowSelection(undefined);
    });
  }, [debouncedQuery, getQueryString, searchQuery]);

  const handleSearch = React.useCallback((searchStr: string) => {
    if (!searchStr || !searchStr.length) {
      setSearchQuery(undefined);
      return;
    }
    setSearchQuery(`&search=${searchStr}`);
  }, []);

  const changePage = React.useCallback((newPage: number) => {
    setPage(newPage);
    setRowSelection(undefined);
  }, []);

  const handleRefresh = React.useCallback(() => {
    setRowSelection(undefined);
    mutate();
  }, [mutate]);

  const selectedIds = React.useMemo(() => {
    const rowIndexes = rowSelection ? Object.keys(rowSelection) : [];

    if (!rowIndexes.length) return [];

    const tasks = data || [];
    const ids = rowIndexes.reduce((prev, curr) => {
      const item = tasks[(curr as unknown) as number];
      if (item) {
        return [...prev, item.id];
      }
      return prev;
    }, [] as string[]);

    return ids;
  }, [data, rowSelection]);

  const exportBatchTasks = React.useCallback(async () => {
    if (!selectedIds.length) return;
    const rowIndexes = rowSelection ? Object.keys(rowSelection) : [];

    if (!rowIndexes) return;

    const tasks = data || [];

    const eventIds = rowIndexes.reduce((prev, curr) => {
      const item = tasks[(curr as unknown) as number];
      if (item.event_id) {
        return [...prev, item.event_id];
      }
      return prev;
    }, [] as string[]);

    if (!eventIds.length) return;

    await exportBatch(eventIds);
    setRowSelection({});
  }, [data, exportBatch, rowSelection, selectedIds]);

  const context = {
    changePage,
    changeItemsPerPage,
    onSearch: handleSearch,
    itemsPerPage,
    page,
    hasNextPage,
    tasks: data || [],
    totalItems,
    refresh: handleRefresh,
    rowSelection,
    setRowSelection,
    selectedIds,
    isLoading: isValidating,
    responsibleFilter,
    onChangeResponsible: setResponsibleFilter,
    exportBatchTasks,
  };

  return (
    <TasksContext.Provider value={context}>{children}</TasksContext.Provider>
  );
};

export function useTasks(
  selector?: (params: TasksContextData) => Partial<TasksContextData>,
): TasksContextData {
  const context: TasksContextData = useContext(TasksContext);

  if (!context) {
    throw new Error('useTasks must be used within an TasksProvider');
  }
  const memoized = React.useMemo(() => {
    if (selector) return selector(context);
    return context;
  }, [context, selector]);

  return memoized as TasksContextData;
}
