import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Menu>
      <MenuButton
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="full"
        w="full"
        bg="transparent"
        p={0}
        _hover={{ background: 'transparent' }}
        _active={{ background: 'transparent' }}
        as={Button}
      >
        {children}
      </MenuButton>
      <MenuList zIndex={100}>
        <MenuItem onClick={() => changeLanguage('pt-BR')}>
          <Flex alignItems="center">
            <Box className="fi fi-br" mr="8px" />
            Português (Brasil)
          </Flex>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('en')}>
          <Flex alignItems="center">
            <Box className="fi fi-us" mr="8px" />
            English
          </Flex>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('es')}>
          <Flex alignItems="center">
            <Box className="fi fi-es" mr="8px" />
            Español
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitcher;
