import React from 'react';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { NotificationsListProvider } from '@/hooks/Notifications/notificationsList';
import { useTranslation } from 'react-i18next';

import { Container, Tabs } from './styles';
import NotificationsList from './components/NotificationsList';
import ReadAll from './components/ReadAll';

const Notifications: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Tabs>
        <TabList>
          <Tab>{t('notifications.new')}</Tab>
          <Tab>{t('notifications.read')}</Tab>
        </TabList>

        <TabPanel>
          <NotificationsListProvider read={false}>
            <NotificationsList />
            <ReadAll />
          </NotificationsListProvider>
        </TabPanel>
        <TabPanel>
          <NotificationsListProvider read>
            <NotificationsList />
          </NotificationsListProvider>
        </TabPanel>
      </Tabs>
    </Container>
  );
};

export default Notifications;
