import CollectionItemInput from '@/components_v2/inputs/CollectionItemInput';
import { CollectionItemValue } from '@/components_v2/inputs/CollectionItemInput/CollectionItemInput.types';
import config from '@/Constants';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { DeleteIcon } from '@chakra-ui/icons';
import { IconButton, List, ListItem, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

export const CollectionsCombo: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState, field } = useField();

  const value = React.useMemo(() => {
    if (fieldState) {
      if (Array.isArray(fieldState)) {
        return fieldState;
      }
      return [fieldState];
    }
    return [];
  }, [fieldState]);

  const addItem = React.useCallback(
    (collectionItem?: CollectionItemValue) => {
      if (!collectionItem) return;

      const { item } = collectionItem;
      onChange([...value, createAnswer(item.name, item.id)]);
    },
    [onChange, value],
  );

  const removeItem = React.useCallback(
    (id: string) => {
      onChange([...value.filter((e) => e.collectionItemId !== id)]);
    },
    [onChange, value],
  );

  return (
    <FormControlComponent isInvalid={error}>
      <VStack spacing="4" alignItems="flex-start">
        <List spacing="4" w="full">
          {value.map((item) => (
            <ListItem
              rounded="md"
              borderWidth="thin"
              padding="1.5"
              key={item.id}
              gap="2"
              alignItems="center"
              display="flex"
            >
              <Text flex="1">{item.content}</Text>

              <IconButton
                onClick={() => removeItem(`${item.collectionItemId}`)}
                aria-label="Delete"
                colorScheme="red"
                icon={<DeleteIcon />}
              />
            </ListItem>
          ))}
        </List>
        <CollectionItemInput
          queryKey={`${config.QUESTIONS_URL}/${field.id}/collections`}
          onChange={addItem}
        />
      </VStack>
    </FormControlComponent>
  );
};
