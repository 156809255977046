import { useTasks } from '@/screens/Audit/Dashboard/hooks/tasks';
import { HStack, IconButton, Select, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const TasksListPages: React.FC = () => {
  const { t } = useTranslation();
  const {
    changeItemsPerPage,
    changePage,
    hasNextPage,
    itemsPerPage,
    page,
    totalItems,
  } = useTasks();

  const hasPrevPage = React.useMemo(() => {
    return page > 1;
  }, [page]);

  return (
    <HStack
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      px={6}
      py={2}
      bg="white"
      borderRadius="4px"
    >
      <HStack>
        <Text whiteSpace="nowrap">{t('tasks.lines_per_page')}</Text>
        <Select
          value={itemsPerPage}
          onChange={(e) => changeItemsPerPage(parseInt(e.target.value, 10))}
          size="sm"
        >
          <option>8</option>
          <option>16</option>
        </Select>
      </HStack>
      <HStack>
        <Text color="gray.500">
          <Text as="span" color="gray.700">
            {`${(page - 1) * itemsPerPage + 1}-${Math.min(
              (page - 1) * itemsPerPage + itemsPerPage,
              totalItems,
            )}`}
          </Text>
          {` ${t('tasks.of')} ${totalItems}`}
        </Text>
        <IconButton
          disabled={!hasPrevPage}
          cursor={hasPrevPage ? 'pointer' : 'unset'}
          size="xs"
          variant="ghost"
          aria-label="pagina anterior"
          as={FiChevronLeft}
          onClick={() => {
            if (hasPrevPage) changePage(page - 1);
          }}
        />
        <IconButton
          disabled={!hasNextPage}
          cursor={hasNextPage ? 'pointer' : 'unset'}
          size="xs"
          variant="ghost"
          aria-label="proxima pagina"
          as={FiChevronRight}
          onClick={() => {
            if (hasNextPage) changePage(page + 1);
          }}
        />
      </HStack>
    </HStack>
  );
};

export default TasksListPages;
