import React, { useCallback } from 'react';

import { useActionSheet } from '@hooks/actionSheet';
import { ActionCableProvider } from '@hooks/actionCable';
import Notifications from '@/screens/utils/ActionSheets/Notifications';

import { OpenActionSheetProps } from '@/hooks/interfaces/actionSheet';
import { useNotifications } from '@/hooks/Notifications/notifications';
import Main from './Main';

const actionSheetNotificationsConfig: OpenActionSheetProps = {
  children: (
    <ActionCableProvider>
      <Notifications />
    </ActionCableProvider>
  ),
  title: 'Notificações',
  key: 'notifications',
  side: 'left',
  destroyOnClose: true,
};

const NotificationsIcon: React.FC = () => {
  const { openActionSheet } = useActionSheet();
  const { pendingNotifications } = useNotifications();

  const onOpenNotifications = useCallback(() => {
    openActionSheet(actionSheetNotificationsConfig);
  }, [openActionSheet]);

  return (
    <Main
      onOpenNotifications={onOpenNotifications}
      hasOpenNotification={pendingNotifications > 0}
    />
  );
};

export default NotificationsIcon;

export { actionSheetNotificationsConfig };
