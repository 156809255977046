import { FeedbackInfo } from '@components/Feedback';
import { RemoteQueryFilter } from '@queryfilter/adapters';
import {
  FetchOptionsResponse,
  FiltersCreateData,
  FilterType,
  InitialFiltersData,
  SelectedFilter,
} from '@queryfilter/domain/interfaces';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  FilterContextData,
  ScreenFiltersData,
  SelectedOptionsState,
} from './interfaces/filter';

/** Default implementation for filters */
function useFilterContext() {
  const [filters, setFilters] = useState<FiltersCreateData>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsState>(
    {} as SelectedOptionsState,
  );

  const handleError = useCallback((error: Error) => {
    FeedbackInfo({
      mainText: 'Atenção!',
      subText: error.message,
    });
  }, []);

  const updateFilters = useCallback(() => {
    const options: SelectedFilter[] = [];
    Object.keys(selectedOptions).forEach((column) => {
      const option = selectedOptions[column];
      options.push(option);
    });
    RemoteQueryFilter.updateFilters(options);
  }, [selectedOptions]);

  const getQueryString = useCallback(() => {
    return RemoteQueryFilter.serializeFilter();
  }, []);

  const getSelectedOptions = useCallback(() => {
    return RemoteQueryFilter.selectedOptions();
  }, []);

  const getFilters = useCallback(
    async (
      screen: string,
      initialFilters: InitialFiltersData[] | null = null,
    ): Promise<ScreenFiltersData> => {
      try {
        const filtersData = await RemoteQueryFilter.getFilters(screen);
        const newSelectedOptions: SelectedOptionsState = {};
        for (let i = 0; i < filtersData.length; i++) {
          const filter = filtersData[i];
          const initialFilter =
            initialFilters &&
            initialFilters.find(
              (item) =>
                item.column === filter.column && item.type === filter.type,
            );
          newSelectedOptions[filter.column] = {
            column: filter.column,
            type: filter.type,
            selected: initialFilter ? initialFilter.selected : null,
          };
        }
        setFilters(filtersData);
        setSelectedOptions(newSelectedOptions);
        return filtersData;
      } catch (err) {
        if (err instanceof Error) {
          handleError(err);
        }
        return [];
      }
    },
    [handleError],
  );

  const getOptions = useCallback(
    async (
      column: string,
      page?: number,
      searchValue?: string,
    ): Promise<FetchOptionsResponse> => {
      try {
        setLoading(() => true);
        const optionsData = await RemoteQueryFilter.getOptions(
          column,
          page,
          searchValue,
        );
        setLoading(() => false);
        return optionsData;
      } catch (err) {
        if (err instanceof Error) handleError(err);
        setLoading(() => false);
        return {
          options: [],
          hasNext: false,
        };
      }
    },
    [handleError],
  );

  useEffect(() => {
    if (Object.keys(selectedOptions).length > 0) {
      updateFilters();
    }
  }, [updateFilters, selectedOptions]);

  const selectOption = useCallback(
    (data: SelectedFilter) => {
      if (data.type !== FilterType.SEARCH) {
        Object.keys(selectedOptions).forEach(() => {
          const childFilters = filters.filter((filter) =>
            filter.parentFields.includes(data.column),
          );
          for (let i = 0; i < childFilters.length; i++) {
            const filter = selectedOptions[childFilters[i].column];
            filter.selected = null;
            setSelectedOptions({ ...selectedOptions, [filter.column]: filter });
          }
        });
      }
      setSelectedOptions(() => ({
        ...selectedOptions,
        [data.column]: data,
      }));
    },
    [filters, selectedOptions],
  );

  const clearFilter = useCallback(
    (filter) => {
      const unselectedOptions = {} as SelectedOptionsState;
      Object.keys(selectedOptions).forEach((column) => {
        if (column === filter) {
          unselectedOptions[column] = {
            ...selectedOptions[column],
            selected: null,
          };
        } else {
          unselectedOptions[column] = {
            ...selectedOptions[column],
          };
        }
      });
      const together = { ...selectedOptions, ...unselectedOptions };
      setSelectedOptions(() => together);

      const options: SelectedFilter[] = [];
      Object.keys(together).forEach((column) => {
        const option = together[column];
        options.push(option);
      });

      RemoteQueryFilter.updateFilters(options);
    },
    [selectedOptions],
  );

  const clearFilters = useCallback(() => {
    const unselectedOptions = {} as SelectedOptionsState;
    Object.keys(selectedOptions).forEach((column) => {
      unselectedOptions[column] = {
        ...selectedOptions[column],
        selected: null,
      };
    });
    setSelectedOptions(unselectedOptions);
    RemoteQueryFilter.updateFilters([]);
  }, [selectedOptions]);

  return {
    getFilters,
    getOptions,
    selectOption,
    getQueryString,
    selectedOptions,
    clearFilters,
    clearFilter,
    getSelectedOptions,
    loading,
  };
}

export type UseFilterContext = typeof useFilterContext;

const FilterContext = createContext<FilterContextData>({} as FilterContextData);

type FilterProviderProps = { useFilterImpl?: UseFilterContext };

export const FilterProvider: React.FC<FilterProviderProps> = ({
  children,
  useFilterImpl = useFilterContext,
}) => {
  const value = useFilterImpl();

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export function useFilter(): FilterContextData {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used whithin an FilterProvider');
  }

  return context;
}
