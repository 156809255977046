import { NotificationsTopics } from '@/hooks/Notifications/utils/NotificationsTopics';
import { useFileTransferDrawer } from '@/screens/FileTransfer/components/FileTransferDrawer';
import React, { useCallback, useEffect, useReducer } from 'react';

import Main from './Main';

const reducer = (state: number, action: { type: 'increase' | 'clear' }) => {
  switch (action.type) {
    case 'increase':
      return state + 1;

    case 'clear':
      return 0;

    default:
      return state;
  }
};

const FileNotifications: React.FC = () => {
  const open = useFileTransferDrawer();
  const [pendingCount, dispatch] = useReducer(reducer, 0);

  const onOpenNotifications = useCallback(() => {
    open();

    dispatch({ type: 'clear' });
  }, [open]);

  useEffect(() => {
    const unsubImport = NotificationsTopics.subscribeTopic('Import', () => {
      dispatch({ type: 'increase' });
    });
    const unsubExport = NotificationsTopics.subscribeTopic('Export', () => {
      dispatch({ type: 'increase' });
    });

    return () => {
      unsubImport();
      unsubExport();
    };
  }, []);

  return (
    <Main
      onOpenNotifications={onOpenNotifications}
      hasOpenNotification={pendingCount > 0}
    />
  );
};

export default FileNotifications;
